
/* Fonts */
/*
font-family: $Cinzel;
font-family: $CinzelDecorative;
font-family: $Roboto;
*/

/* Media Screens */


html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}


body {
	margin-top: 1px;
	@include fz(18px, 1.4, #292b2e, 400);
	font-family: $Cinzel;
	background: #fff;
	/*border-top: 7px solid #ad171f;*/
	@include break(medium) {
		font-size: 15px;
	}
	@include break(mobile) {
	}

	@include break(x-small) {
		min-width: 320px;
		font-size: 14px;
	}
}

.container {
	width: 1240px;
	margin: 0 auto;
	padding: 0;
	
	@include break(desktop) {
		width: 1170px;
	}
	@include break(medium) {
		width: 990px;
		padding-left: 15px;
		padding-right: 15px;
	}	
	@include break(neutral) {
		width: 721px;
	}
	@include break(mobile) {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
	@include break(x-small) {
		width: 100%;
		min-width: 320px;
	}
}



/**   nav menu  **/

#nav {
	@include break(mobile) {display: none;}
	@include clearfix();
	z-index: 10;
	position: relative;
	float: left;

	.sf-menu {
		position: relative;
		font-size: 0;
		line-height: 0;
		white-space: nowrap;	
		text-align: center;	
		display: inline-block;

		@include break(neutral) {
			text-align: center;		
			padding-right: 0;
			white-space: normal;
			padding-top: 2px;
			padding-bottom: 10px;
		}

		 > li {
			margin: 0;
			padding: 0;
			position: relative;
			display: inline-block;
			font-size: 0;
			line-height: 0;
			@include break(custom) {
				padding-left: 8px;
				padding-right: 8px;
			}
			@include break(medium) {
				padding-left: 6px;
				padding-right: 6px;
			}
			@include break(neutral) {
				padding-left: 0px;
				padding-right: 0px;
				padding-top: 2px;
				padding-bottom: 2px;
			}

			& > a {
				font-family: 'Encode Sans', sans-serif;
				color: #4d4d4d;
				font-size: 16px;
				line-height: 1.3;
				font-weight: 400;
				text-decoration: none;
				display: block;
				margin: 0;
				padding: 41px 20px 12px;
				transition: .2s ease-in-out;
				text-transform: uppercase;
				position: relative;
				&:after {
					content:"";
					display: block;
					width: 50px;
					height: 1px;
					background: #d8d8d8;
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%) scale(0);
					transition: .2s ease-in-out;
				}							
				@include break(medium) {
					font-size: 14px;
					padding-top: 25px;
					padding-left: 15px;
					padding-right: 15px;
				}
				@include break(neutral) {
					padding-left: 4px;
					padding-right: 4px;
					font-size: 13px;
					padding-top: 12px;
					padding-bottom: 2px;
				}
			}

			&.sfHover,
			&:hover {
				color: #ad171f;
				& > a {
					color: #ad171f;
					&:after {
						transform: translateX(-50%) scale(1);
					}
				}
			}
			& > a.current-menu-item {
				color: #000;	
				&:hover {
					color: #ad171f;	
				}
			}
	
			& > a.sf-with-ul {
				&::before {
					content:'';
					display: block;
					float: right;
					font-family: "FontAwesome";
					font-size: 16px;
					color: #d7d7d7;
					margin-left: 6px;
					font-weight: 400;
					margin-right: -8px;
					@include break(medium) {
						display: none;						
					}
				}
			}	
		}
		li + li {			
			@include break(neutral) {
				background: none;
			}
		}


		.dd_menu {
			position: absolute;
			background: rgba(0, 0, 0, .9);
			min-width: 180px;
			display: none;
			text-align: left;
			border: 0;
			padding: 10px 10px; 
			margin-top: 10px;
			border-radius: 0 0 5px 5px;
	
			li {
				display: block;				
				position: relative;
				a {
					@include fz(16px, 1.2, #ffe49a, 400);								
					text-decoration: none;
					border-left: none;
					padding: 4px 15px;
					display: block;
					letter-spacing: 0.5px;
					font-family: $Cinzel;
					&:hover {
						color: #fff;
					}
				}
			}
			li + li {
				border-top: 1px #000000 solid;
			}
		}
	}
}

.sticky-wrapper {
    transition: 0.3s ease;
}

/**     H E A D E R  **/
header.site-header {
	padding: 0;
	/*position: fixed;*/
	z-index:  1500;
	position: relative;
	background-color: #fff;
	border-top: 7px solid #ad171f;
	transition: 0.3s ease;
	@include break(mobile) {
		border-top: none;
	}
	&.stuck {
		border-top-width: 3px;
		border-bottom: 1px solid #d8d8d8;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		.slicknav_menu {
		}
		.slicknav_nav {
			position: 16px;
		}
		.logo {
			padding-top: 10px;
			padding-bottom: 5px;
			@include break(neutral) {
				padding-top: 15px;
				padding-bottom: 0;
			}
			@include break(mobile) {
				padding-top: 5px;
			}
		}
		p.phone {
			padding-top: 15px;
			@include break(neutral) {
				padding-top: 4px;
			}
			@include break(mobile) {
				padding-top: 0;
			}
		}
		.btn {
			margin-top: 15px;
			margin-bottom: 15px;
			@include break(neutral) {
				margin-top: 10px;
				margin-bottom: 10px;
			}
		}
		#nav .sf-menu>li>a {
			padding-top: 30px;
			@include break(medium) {
				padding-top: 20px;
			}
			@include break(neutral) {
				padding-top: 12px;
			}
		}
	}
	& > img {
		width: 100%;		
	}

	#wrapper {
		width: 100%;
		padding: 0;
		clear: both;
		@include break(neutral) {
			
		}
		@include break(mobile) {
		}

		.facebook {
			@include break(mobile) {
				display: none;
			}			
		}
	}

	.logo {
		float: left;
		padding: 13px 31px 19px 46px;
		transition: 0.3s ease;
		@include break(medium) {
			width: 174px;
			padding: 15px 0 0 15px;
		}
		@include break(neutral) {
			width: 90px;
			padding-left: 10px;
			margin-right: 10px;
		}

		@include break(mobile) {
			float: none;
			width: 190px;
			display: block;
			text-align: center;
			position: fixed;
			padding-top: 0;
			z-index: 1000;
			left: 50%;
			margin-left: -95px;
			margin-top: -4px;
		}
		a {
			text-decoration: none;
			display: block;			
			img {
				float: left;
				@include break(neutral) {
					width: 90px;
				}
				@include break(mobile) {
					width: 169px;
					display: block;
					margin: 16px auto;
					float: none;
					padding-right: 0;
				}
			}
		}
	}

	.btn {
		float: right;
		margin: 32px 32px 31px 18px;
		transition: all .3s;
		@include break(medium) {
			font-size: 12px;
			margin: 20px 20px 0 10px;

		}
		@include break(neutral) {
			margin-top: 10px;
			margin-right: 10px;
			font-size: 10px;
		}
	}

	.phone {
		float: right;
		padding-top: 31px;
		font-family: $Cinzel;
		position: relative;
		transition: .3s ease;
		@include break(medium) {
			padding-top: 20px;
			padding-bottom: 8px;
			padding-right: 30px;
		}
		@include break(neutral) {
			padding-top: 4px;
			padding-right: 20px;
		}
		@include break(mobile) {
			padding: 0;
			margin: 0;
			position: fixed;
			right: 24px;
			top: 20px;
			z-index: 500;
		}

		span {
			display: block;
			padding: 0;
			text-transform: uppercase;
			font-family: 'Encode Sans', sans-serif;
			font-size: 15px;
			line-height: 15px;
			color: #000;
			@include break(medium) {
				font-size: 13px;
			}
			@include break(neutral) {
				font-size: 12px;
			}
			@include break(mobile) {
				display: none;
			}

		}

		a.tel {
			font-family: $Cinzel;
			display: block;
			color: #000;
			font-size: 22px;
			line-height: 22px;
			font-weight: 700;
			font-family: 'Encode Sans', sans-serif;
			&:hover {
				cursor: pointer;
				opacity: .8;
			}
			@include break(medium) {
				font-size: 20px;
			}
			@include break(neutral) {
				font-size: 18px;
			}	
			@include break(mobile) {
				height: 38px;
				width: 38px;
				line-height: 38px;
				text-align: center;
				border-radius: 50%;
				background-image: linear-gradient(to right, #ad171f, #dd0f1a);
				z-index: 1000;
				position: relative;
				font-size: 0;
			}
			&::before {
				@include break(mobile) {
					content: "\f095";
					color: #fff;
					font-size: 20px;
					font-family: "FontAwesome";
					font-weight: 300;
				}
			}

			@include break(x-small) {

			}	
		}
	}

}




/**    B R E A D C R U M B S      B R E A D C R U M B S         **/

.breadcrumbs {
	margin-bottom: -30px;
	padding-top: 30px;
	@include break(mobile) {
		margin-bottom: 0;
		padding-top: 20px;
	}
	ul {
		margin: 0;
		padding: 0;
		li {
			float: left;
			@include fz(14px, 1.2, #9b9b9b, 400);						
			padding-right: 10px;
			span {
				font-family: "FontAwesome";
				color: #d8d8d8;				
			}
			a {
				text-decoration: none;
				color: #9b9b9b;
				&:hover {
					text-decoration: underline;
					color: #000;
				}
			}
		}
	}
}


/**    S L I D E R         **/
#slider {
	/*padding-top: 108px;*/
	background: #1c1b19;
/*	@include break(medium) {
		margin-top: 90px;
	}
	@include break(neutral) {
		margin-top: 66px;
	}
	@include break(mobile) {
		background: none;
		padding-top: 21px;
	}*/
	&:hover {
		.flex-direction-nav {
			opacity:1;
		}
	}
	& > img {
		width: 100%;
	}
	&.no-nav {
		margin-bottom: 15px;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
		.flex-direction-nav {
			opacity:0!important;
		}
	}
}


.flexslider {
	position: relative;
	overflow: hidden;
	background-color: #000;
	@include break(mobile) {
		background: none;
		padding: 0;
		margin-top: 86px;

	}
	.touch-indicator {
		background: url(//www.bobbittglass.com/wp-content/themes/glass/css/../images/icons/touch-indicator.png) 50% 50% no-repeat;
		height: 72px;
		width: 69px;
		margin-left: -35px;
		position: absolute;
		top: 35%;
		left:50%;
		z-index: 10;
    	animation: shake 2s infinite;
    	transition: .5s;

	}
	.disabled {
		opacity: 0;
		transition: .5s;
	}
	ul.slides {
		overflow: hidden;
		@include break(mobile) {
		}
		li {
			display: none;			
			img {
				width: 100%;
				opacity: .7;
				  @include break(x-small) {
				  	max-width: 480px;
				  	width: 480px;
				  	position: relative;
				  	left:50%;
				  	transform: translateX(-50%);
				  }
				  @include break(mobile) {
				  	opacity: 1;
				  }		
			}
			div {
				color: #fff;
				text-transform: uppercase;
				position: absolute;
				top: 50%;
				text-align: center;
				width: 100%;
				transform: translateY(-50%);
				text-shadow: 0 2px 1px rgba(0, 0, 0, 0.78);
				font-family: 'Encode Sans', sans-serif;
				@include break(mobile) {
					position: relative;
					padding: 18px 18px 20px 24px;
					top: 0;
					transform: translateY(0);
				}
				h1 {
					font-size: 50px;
					font-weight: 700;
					line-height: normal;
					@include break(medium) {
						font-size: 38px;
					}
					@include break(neutral) {
						font-size: 28px;
					}
					@include break(mobile) {
						font-size: 18px;
						margin-bottom: 10px;
						margin-top: 0;
					}
				}
				p {
					font-size: 41px;
					font-weight: 400;
					line-height: normal;
					@include break(medium) {
						font-size: 34px;
					}
					@include break(neutral) {
						font-size: 24px;
					}
					@include break(mobile) {
						font-size: 15px;
						margin-bottom: 0;
					}
				}
			}
		}
		.sub-page & li img {
			@include break(mobile) {
			  	padding-bottom: 0;
			  }
		}	
	}

	.flex-control-nav.flex-control-paging {
		position: absolute;
		bottom: 20px;
		width: 100%;
		left:0;
		z-index: 101;
		text-align: center;		
		
	    @include break(neutral) {
	  	  bottom: 6px;
		}
	    @include break(mobile) {
	  	  display: none;	  	
		}		

		li {
			display: inline-block;
			text-indent: -9999px;
			padding: 0 10px;
			a {
				display: block;
				/*background-color: rgba(255, 228, 154, 0.6);*/
				background-color: rgba(6, 6, 6, 0.21);
				/*box-shadow: 0 2px 4px 0 rgba(6, 2, 0, 0.32);	*/			
	  			/*opacity: 1;*/
	  			opacity: 1;
	  			/*width: 10px;
	  			height: 10px;*/
	  			width: 9px;
	  			height: 9px;
 				border-radius: 50%;
 				border: 1px #fff solid;
 				position: relative;
 				
	  			&:hover,
	  			&.flex-active {
	  				background-color: rgba(6, 6, 6, 0.21);
	  				/*background: #282828;*/
	  				/*border-color:#fffbe7;*/
	  				border: 1px #fff solid;
	  				/*opacity: 1;*/
		  			/*width: 14px;
		  			height: 14px;*/
		  			width: 13px;
		  			height: 13px;
					/*box-shadow: 0 2px 8px 0 rgba(6, 2, 0, 0.8);	*/			
					margin-top: -2px;
					margin-left: -2px;
					margin-right: -2px;
					&:before {
						content: " ";
						display: block;
						width: 9px;
						height: 9px;
						left: 1px;
						top: 1px;
						border-radius: 50%;
						background-color: #fff;
						position: absolute;
					}
	  			}

			}
		}
	}

	.wrapper-nav {
		position: absolute;
		top:50%;
		width: 100%;
		z-index: 1001;
		left:0; 
		transform: translateY(-50%);

		@include break(custom) {
		}
		@include break(medium) {
		}
		@include break(neutral) {
		}
		@include break(mobile) {
			display: none;			
		}

		.flex-direction-nav {
			opacity:0;
			transition: .2s ease-in-out;			
			padding: 5px;
			li {
				float: left;
				margin-left: 33px;
				a {
					display: block;
					background-image: url('../img/slide-arrow-left.png');
					width: 17px;
					height: 58px;
					font-size: 0;
					text-decoration: none;					
					outline: none;
					&:hover {
						opacity: .88;
					} 
				}
			}
			li + li {
				float: right;
				margin-right: 33px;
				a {
					background-image: url('../img/slide-arrow-right.png');
					font-size: 0;
				}
			}
		}
	}

	.caption {
		position: absolute;
		bottom: 20px;
		left: 50%;
		margin-left: -80px;
		@include break(medium) {
			bottom: 10px;
		}

		@include break(mobile) {
			position: relative;
			top: 0;
			transform: translateY(0);
			left:0;
			margin-left: 0;
			text-align: center;
			background: none;
			padding-top: 18px;
		}
		@include break(x-small) {
			min-height: 55px;
			padding-top: 0;
			margin-top: 10px;
			bottom: 0
		}

		p {
			@include fz(40px, 1.25, #ffe49a, 400);	
			display: inline-block;
			padding: 140px 0 0 120px;
			text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.85);
			letter-spacing: 1px;
			font-family: $Cinzel;
			position: relative;
			max-width: 540px;
			&::after {
				content: url(../img/moo.png);
				position: absolute;
				bottom: 0;
				display: block;	
				left: 0;
				@include break(neutral) {
					display: none;
				}
			}

			@include break(medium) {
				max-width: 480px;
				font-size: 32px;
			}
			@include break(neutral) {
				font-size: 24px;
				max-width: 380px;
			}
			@include break(mobile) {
				padding: 0;
				background: none;
				margin-bottom: 0;
				font-size: 18px;
				br {
					display: none;
				}

			}
			@include break(x-small) {
				top: 50%;
				transform: translateY(-50%);
				position: absolute;
				left:0;
				width: 100%;
				font-size: 24px;
				padding-left: 12px;
				padding-right: 12px;
			}	

		}
	}
}

div.slogan {
	position: relative;
	.inner {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		text-align: center;
		width: 100%;
	}
	img {
	  @include break(mobile) {
	  	max-width: 1080px;
	  	width: 1080px;
	  	position: relative;
	  	left:-340px;
	  }		
	  @include break(x-small) {
	  	left:-480px;
	  }		

	}
	h1 {
		@include fz(40px, 1.25, #f5e8a7, 400);	
		margin: 0;
		padding: 0 0 20px 0;
		@include break(medium) {
			font-size: 36px;
			padding-bottom: 10px;
		}
		@include break(neutral) {
			font-size: 24px;
			padding-bottom: 2px;
		}
	}
	h3 {
		@include fz(24px, 1.25, #fffbe7, 300);	
		font-family: $Roboto;
		max-width: 680px;
		margin-left: auto;
		margin-right: auto;
		@include break(medium) {
			font-size: 20px;
			max-width: 570px;
		}
		@include break(neutral) {
			font-size: 16px;
			padding-top: 12px;
			margin-top: 0;
			max-width: 400px;
		}
		@include break(mobile) {
			font-size: 18px;
			padding-left: 15px;
			padding-right: 15px;
			line-height: 1.4;
		}
	}
	hr {
		display: block;
		padding: 0;
		margin: 0;
		background: url("../img/line.png") 50% 50% no-repeat;
		border: 0;
		height: 22px;
		width: 100%;
		display: block;
	}
}


/**     C O N T E N T          **/
section.bg-grey {
	background-color: #f2f2f2;
	padding-top: 38px;
	padding-bottom: 130px;
	@include break(medium) {
		padding-bottom: 100px;
	}
	@include break(neutral) {
		padding-top: 0;
		padding-bottom: 122px;
	}
	@include break(mobile) {
		padding-top: 24px;
		padding-left: 22px;
		padding-right: 20px;
		padding-bottom: 165px;
		text-align: center; 
	}
	h2 {
		font-family: 'Encode Sans', sans-serif;
		font-size: 30px;
		font-weight: 300;
		color: #000;
		max-width: 760px;
		float: left;
		line-height: normal;
		@include break(medium) {
			font-size: 26px;
		}
		@include break(neutral) {
			max-width: 530px;
			font-size: 21px;
		}
		@include break(mobile) {
			font-size: 24px;
			margin-top: 0;
			margin-bottom: 20px;
			max-width: 100%;
		}
	} 
	a.btn {
		float: right;
		margin-top: 55px;
		margin-right: 10px;
		@include break(medium) {
			font-size: 12px;
		}
		@include break(neutral) {
			font-size: 10px;
			margin-top: 40px;
			margin-right: 0;
		}
		@include break(mobile) {
			float: none;
			font-size: 14px;
			margin: 0 auto;
			font-weight: 300;
		}

	}
	.hr {
		border-top: 1px solid #979797;
		margin-top: 19px;
		@include break(mobile) {
			margin-top: 42px;
			margin-left: -22px;
			margin-right: -20px;
		}
	}
	h3 {
		font-family: 'Encode Sans', sans-serif;
		font-size: 28px;
		font-weight: 700;
		color: #000;
		margin-top: 22px;
		margin-bottom: 10px;
		line-height: normal;
		@include break(neutral) {
			font-size: 24px;
			margin-top: 18px;
		}
		@include break(mobile) {
			margin-top: 41px;
			font-size: 26px;
			font-stretch: condensed;
		}
	}

}
.sub-page {
	/**    S L I D E R         **/
		.flexslider {
			ul.slides {
				max-height: 350px;
				div {
					display: none;
				}
			}
		}
}
section.bg-white {
	background: url('../img/bitmap.jpg') no-repeat right bottom #fff;
	border-bottom: 1px solid #eeeeee;
	padding-bottom: 87px;
	@include break(medium) {
		padding-bottom: 60px;
	}
	@include break(mobile) {
		background-size: contain;
		padding-bottom: 72px;
	}
	.sub-page & {
		padding-top: 45px;
		padding-bottom: 200px;
		min-height: 300px;
		background-size: 50%;
		@include break(neutral) {
			background-size: 50%;
		}
		@include break(mobile) {
			background-size: contain;

		}
		h1 {
			position: relative;
			text-transform: uppercase;
			@include break(mobile) {
					text-align: center;
			}
		}
		
		
		.contacts-phone-address {
			h6 {
				margin-top: 0;
				margin-bottom: 10px;
				font-weight: 700;
				font-size: 20px;
				text-align: justify;
				@include break(mobile) {
					padding-left: 0;
				}
			}
			p {
				font-family: 'Lato', sans-serif;
				font-size: 16px;
				color: #000000;
			}
		}
		
		.one-block + .one-block {
			border-top: 1px solid #979797;
			padding-top: 34px;
		}
		.one-block {
			h2 {
				padding-bottom: 10px;
				@include break(medium) {
					font-size: 26px;
				}
				@include break(neutral) {
					font-size: 22px;
				}
				@include break(mobile) {
					text-align: center;
				}
			}
			h3 {
				font-size: 30px;
				padding-top: 0;
				font-weight: 300;
				@include break(medium) {
					font-size: 28px;
				}
				@include break(neutral) {
					font-size: 24px;
					padding-bottom: 10px;
				}
			}
			p + p {
				margin-bottom: 35px;
			}
			p {
				text-align: justify;
				@include break(neutral) {
					font-size: 16px;
				}
				@include break(mobile) {
					font-size: 16px;
					padding-left: 29px;
					padding-right: 29px;
				}
			}
		}
		.items {
			margin-top: 0;
			.item {
				p {
					@include break(neutral) {
						font-size: 14px;
						margin: 0px 5px 0 5px;

					}
				}
				@include break(neutral) {
					min-height: 197px;
				}
			}
		}
		.what-we-do-block {
			h1 {
				font-size: 40px;
				@include break(medium) {
					font-size: 34px;
				}
				@include break(neutral) {
					font-size: 28px;
				}
				@include break(mobile) {
					text-align: center;
				}
			}
			p {
				padding-top: 10px;
				padding-bottom: 15px;
				@include break(mobile) {
					padding-left: 29px;
					padding-right: 29px;
				}
			}
			h2 {
				padding-bottom: 20px;
				@include break(mobile) {
					text-align: center;
				}
			}
		}
	}

	.items {
		display: flex;
		flex-direction: row;
		margin-top: -190px;
		@include break(medium) {
			margin-top: -150px;
		}
		@include break(neutral) {
			margin-top: -180px;
		}
		@include break(mobile) {
			margin-top: -245px;
		}
		.item {
			width: 20%;
			border: 1px solid #d5d5d5;
			padding: 6px;
			@include break(neutral) {
				width: 100%;
				min-height: 240px;
			}
			@include break(mobile) {
				width: 290px;
				padding: 7px;
				margin: 0 auto;
			}

			p {
				font-family: 'Lato', sans-serif;
				font-size: 16px;
				color: #000000;
				margin: 21px 14px 6px 13px;
				line-height: normal;
				@include break(medium) {
					font-size: 14px;
					margin: 10px 5px 0 5px;
				}
				@include break(mobile) {
					font-size: 16px;
					margin: 17px 18px 10px 16px;
				}
			}
		}
		.item + .item {
			margin-left: 16px;
		}
	}

	h1 {
		@include fz(40px, 1.25, #000, 700);	
		font-family: 'Encode Sans', sans-serif;
		margin: 0;
		padding: 0 0 15px 0;
		@include break(medium) {
			font-size: 30px;
		}
		@include break(neutral) {
			font-size: 24px;
		}
		@include break(mobile) {
			font-size: 30px;
		}
	}
	h2 {
		@include fz(28px, 1.25, #000, 700);	
		font-family: 'Encode Sans', sans-serif;
		margin: 0;
		padding: 0 0 15px 0;
		@include break(medium) {
			font-size: 22px;
		}
		@include break(neutral) {
			font-size: 16px;
		}
		@include break(mobile) {
			font-size: 26px;
		}
		@include break(x-small) {
			font-size: 18px;
		}

	}
	h3 {
		@include fz(28px, 1.25, #000, 700);
		font-family: 'Encode Sans', sans-serif;
		margin: 0;
		padding: 58px 0 15px 0;
		@include break(medium) {
			font-size: 22px;
		}
		@include break(neutral) {
			font-size: 16px;
			padding-top: 40px;
			padding-bottom: 0;
		}
		@include break(mobile) {
			padding-top: 56px;
			font-size: 28px;
			text-align: center;
		}

	}	
	h4 {
		@include fz(20px, 1.25, #a43719, 400);	
		font-family: $Roboto;
		margin: 0;
		padding: 0 0 15px 0;
		@include break(custom) {
			font-size: 20px;
		}
		@include break(neutral) {
			font-size: 16px;
		}
	}
	/*h5 {
		@include fz(18px, 1.25, #1b0b01, 700);	
		font-family: $Roboto;
		margin: 0;
		padding: 0 0 15px 0;
		@include break(custom) {
			font-size: 18px;
		}
		@include break(neutral) {
			font-size: 16px;
		}
	}	
	h6 {
		@include fz(16px, 1.2, #a43719, 400);	
		font-family: $Roboto;
		margin: 0 0 4px 0;
		padding: 0 0 15px 0;
		@include break(custom) {
			font-size: 16px;
		}
		@include break(neutral) {
			font-size: 14px;
		}

	}	*/
	h5 {
		font-family: 'Encode Sans', sans-serif;
		@include fz(20px, 1.25, #000, 300);
		max-width: 803px;
		@include break(medium) {
			font-size: 18px;
			max-width: 710px;
		}
		@include break(neutral) {
			font-size: 16px;
			max-width: 500px;
		}
		@include break(mobile) {
			padding-left: 29px;
			padding-right: 22px;
			margin-top: 31px;
			font-size: 20px;
			max-width: 100%;
		}
	}
	h6 {
		font-family: 'Lato', sans-serif;
		@include fz(18px, 1.25, #000, 300);
		margin-bottom: 35px;
		max-width: 803px;
		@include break(medium) {
			font-size: 16px;
			max-width: 710px;
		}
		@include break(neutral) {
			font-size: 14px;
			margin-bottom: 50px;
			max-width: 500px;
		}
		@include break(mobile) {
			padding-left: 29px;
			padding-right: 22px;
			font-size: 18px;
			margin-top: 20px;
			margin-bottom: 20px;
			max-width: 100%;
		}
	}
	p {
		font-family: 'Lato', sans-serif;
		@include fz(18px, 1.25, #000, 500);
	}
	.btn {
		 border: solid 1px #dadada;
		 background: transparent;
		 font-family: 'Lato', sans-serif;
		 font-size: 14px;
		 font-weight: 700;
		 color: #181818;
		 box-shadow: none;
		 text-transform: none;
		 &:hover {
				background-color: #eeeeee;
				color: #181818;
				} 
		&:active {
			background-color: #dddddd;
		}
		&:focus {
			background-color: #dddddd;
			opacity: 1;
		}
		&::after {
		content: "\f105";
		font-family: 'Fontawesome';
		display: inline-block;
		padding-left: 10px;
		color: #9b9b9b;
		font-weight: 400;
		}
		@include break(medium) {
			font-size: 12px;
		}
		@include break(neutral) {
			font-size: 10px;
		}
		@include break(mobile) {
			padding-left: 16px;
			font-size: 14px;
			margin-left: 34px;
		}
	}

	

}
section.bg-white.contacts-bg {
	@include break(neutral) {
		padding-bottom: 30px;
	}
	@include break(mobile) {
		padding-left: 29px;
		padding-right: 29px;
	}
	.contacts-bg-content {
		h1 {
			font-size: 48px;
			@include break(medium) {
			font-size: 48px;
			}
			@include break(neutral) {
				font-size: 36px;
			}
		}
		img {
			width: 100%;
			@include break(mobile) {
				width: auto;
				padding-bottom: 20px;
			}
		}
		h2 {
			padding-top: 75px;
			padding-bottom: 20px;
			font-size: 36px;
			@include break(medium) {
			font-size: 36px;
			}
			@include break(neutral) {
				font-size: 34px;
			}
		}
	}
}






/* S I D E B A R   */
.sidebar {	padding-right: 20px;}



/* G A L L E R Y */
a.gallery {
	display: block;
	border: 1px #7e5d47 solid;
	padding: 4px;
	background: #ffe49a; 
	margin-bottom: 20px;
	margin-top: 10px;
	position: relative;	
	text-decoration: none;
	border-radius: 4px;
	width:48%;
	float: left;
	margin-right: 2%;
	&::before {
		opacity: 0;
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		@include link_trans();
		background: rgba(255, 255, 255, .2);

	}
	&::after {
		opacity: 0;
		content: '';
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		width: 50px;
		height: 50px;
		@include link_trans();
		font-family: 'FontAwesome';
		text-align: center;
		color: #fff;
		font-size: 30px;
		transform: translate(-50%, -50%);
	}
	&:hover {
		&::before,
		&::after {
			opacity: 1;
		}
	}
	img {
		width: 100%;
	}
	p {
		text-align: center;
		padding-top: 15px;
		color: #a43719;
		font-size: 20px;
	}
}



a.facebook {
	font-size: 0;
	float: right;
	margin-top: 25px;			
	display: block;
	border-radius: 4px;
	overflow: hidden;
	@include break(neutral) {
		margin-top: 0;
	}

	img {
		display: block;
		padding: 0;
		margin: 0;
	}
}


/**     F O O T E R        F O O T E R         F O O T E R         **/
footer.site-footer {
	padding: 66px 32px 47px 50px;
	overflow: hidden;
	@include break(medium) {
		padding: 25px 15px;
	}
	@include break(neutral) {
		padding: 20px 10px;
	}
	@include break(mobile) {
		background-color: #fff;
		padding: 31px 36px 39px 44px;

	}
	.footer-address, .footer-phone {
		float: left;
		position: relative;
		margin-right: 18%;
		@include break(medium) {
			margin-right: 10%;
		}
		@include break(neutral) {
			margin-left: 50px;
		}
		@include break(mobile) {
			float: none;
			margin-left: 0;
		}

		div {
			margin-bottom: 18px;
			@include break(medium) {
				margin-bottom: 5px;
			}
			@include break(mobile) {
				margin-bottom: 19px;
			}
		}
		i {
			float: left;
			padding-right: 8px;
			color: #cecece;
			font-size: 18px;
			@include break(medium) {
				font-size: 16px;
			}
			@include break(neutral) {
				font-size: 14px;
			}
			@include break(mobile) {
				font-size: 18px;
			}
		}

		h6 {
			margin: 0;
			text-transform: uppercase;
		 	font-family: 'Lato', sans-serif;
		 	font-size: 14px;
		 	color: #7c7c7c;
		 	@include break(medium) {
		 		font-size: 12px;
		 	}
			@include break(neutral) {
				font-size: 10px;
			}
			@include break(mobile) {
				font-size: 14px;
			}
		}

		p {
			font-family: 'Lato', sans-serif;
			font-size: 16px;
			color: #181818;
			@include break(medium) {
				font-size: 14px;
			}	
			@include break(neutral) {
				font-size: 12px;
				min-width: 160px;
			}
			@include break(mobile) {
				font-size: 16px;
			}
			
		}
		.tel {
			font-family: 'Lato', sans-serif;
			font-size: 24px;
			font-weight: 700;
			color: #181818;
			@include break(medium) {
				font-size: 20px;
			}
			@include break(neutral) {
				font-size: 16px;
			}
			@include break(mobile) {
				font-size: 24px;
			}
		}
	
	}
	.footer-phone {
		margin: 0;
	}	

	.footer-logo {
		float: left;
		margin-right: 14%;
		@include break(medium) {
			margin-right: 10%;
		}
		@include break(neutral) {
			margin-right: 20px;
		}
		@include break(mobile) {
			float: none;
			display: block;
			text-align: center;
		}
		a {
			max-width: 190px;
			text-align: center;
			text-decoration: none;
			display: block;
			@include break(medium) {
				max-width: 160px;
			}		
			@include break(neutral) {
				max-width: 140px;
			}	
			@include break(mobile) {
				width: 170px;
				text-align: left;
				margin-bottom: 27px;
				img {
					max-width: 170px;
				}
			}
		}		
	}
	.btn {
		float: right;
		@include break(medium) {
			font-size: 12px;
		}
		@include break(neutral) {
			font-size: 10px;
		}
		@include break(mobile) {
			float: none;
			font-size: 14px;
			margin-top: 39px;
			width: 100%;
			max-width: 295px;
		}
	}

}


/*-------------------    Copyright  --------------------*/
.copyright {
	padding: 26px 33px 13px 50px;
	border-top: 7px solid #eee;
	@include break(mobile) {
		background-color: #fff;
		padding: 26px 42px;
	}
	.footer-logo-right {
		margin-top: -40px;
		margin-left: 38px;
		width: 84px;
		height: 74px;
		@include break(mobile) {
			float: none !important;
			margin: 0 auto;
			display: block;
		}
		img {
			@include break(medium) {
			}
			@include break(neutral) {
			}
			@include break(mobile) {
				float: none;
			}
		}
	}
	p {
		font-family: 'Lato', sans-serif;
		font-size: 15px;
		color: #8a8a8a;
		margin: 0;
		line-height: normal;
		@include break(medium) {
			font-size: 14px;
		}
		@include break(neutral) {
			font-size: 12px;
		}			
		@include break(mobile) {
			float: none!important;
			width: 100%;
			text-align: center;
			font-size: 15px;
			margin-bottom: 15px;
		}

		a {
			font-weight: 700;
			text-decoration: underline;
			color: #8a8a8a;
			vertical-align: baseline;

			&:hover {
				text-decoration: none;			
			}
			img {
				float: right;
				margin-top: -40px;
				padding-left: 38px; 
				@include break(mobile) {
					margin-top: -20px;
					float: none;
				}
				@include break(x-small) {
					margin-top: 10px;
					float: none;
					padding-left: 0;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}
}	

/* C O N D I T I O N A L    L O G I C */
#ajax-contact-form {
	.div_select {
		& > p {
			border: 4px #979797 solid;
			padding: 10px;
			display: none;
			strong {
				display: block;
				margin-left: 5px;
				padding-bottom: 10px;
			}	
			b{
				display: block;
				background: #cb1325;
				color: #fff;
				font-size: 16px;
				text-align: center;
				padding-top: 5px;
				padding-bottom: 6px;
				margin-top: 4px;
				font-weight: 400;
				display: block;
				display: none;
			}
			input[type="checkbox"]	{
				background: #fffdf4;
				border: 1px #979797 solid;
				width: 15px;
				height: 15px;
				margin-right: 12px;
				float: left;
				margin-bottom: 6px;
			}
			label {
				@include fz(16px, 1.2, #24130d, 400);			
				overflow: hidden;
				width: 100%;
				margin-left: 5px;
				margin-bottom: 0;
			}
		}
	}	
}

/**     F O R M    F O R M    F O R M    F O R M    F O R M    F O R M     **/

#ajax-contact-form {
	text-align: left;
	background-color: #ebebeb;
	padding: 30px 40px;
	@include break(medium) {
		padding: 10px 20px;
	}
	@include break(neutral) {
		width: 100%;
	}
	@include break(mobile) {
		padding-bottom: 50px;
	}

	h3 {
		padding-top: 0;
		text-transform: uppercase;
		text-align: center;
		@include break(medium) {
			padding-top: 20px;
			padding-bottom: 10px;
		}
	}
	h3 + p {
		padding-bottom: 30px;
		text-align: center;
		@include break(medium) {
			padding-bottom: 25px;
		}
	}
	p {
		font-family: 'Encode Sans', sans-serif;
		margin-bottom: 0;
		text-align: left;
		font-size: 16px;
		&.middle {
			line-height: 3;
		}
		&.select {
			position: relative;

		}
		input {
			padding: 10px;
			font-size: 16px;
		}
	}
	p:last-child {
		@include break(mobile) {
			text-align: center;
		}
	}
	.signature {
		width: 240px;
	}
}
#ajax-contact-form textarea,
#ajax-contact-form select,
#ajax-contact-form input {
	padding: 4px 12px 6px;
	background: #fff;
	width: 100%;
	border-radius: 0px;
	font-family: 'Lato', sans-serif;
	color: #000;
	font-size: 14px;
	margin: 4px 0 12px;
    border: solid 1px #979797;
}
#ajax-contact-form select{
	font-size: 14px;
	padding-left: 8px;
	height: 36px;
	color: #000;
}

#ajax-contact-form textarea:active,
#ajax-contact-form input:active,
#ajax-contact-form select:active,
#ajax-contact-form select:focus,
#ajax-contact-form textarea:focus,
#ajax-contact-form input:focus {
	box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #979797;
  background: #fffdf4;
	color: #000;
	outline: none;
}
#ajax-contact-form textarea {	height: 132px; max-width: 100%; }
#ajax-contact-form *::-webkit-input-placeholder {color:#4d4d4d; font-family: 'Lato', sans-serif; font-size: 14px;}
#ajax-contact-form *::-moz-placeholder          {color:#4d4d4d; font-family: 'Lato', sans-serif; font-size: 14px;}/* Firefox 19+ */
#ajax-contact-form *:-moz-placeholder           {color:#4d4d4d; font-family: 'Lato', sans-serif; font-size: 14px;}/* Firefox 18- */
#ajax-contact-form *:-ms-input-placeholder      {color:#4d4d4d; font-family: 'Lato', sans-serif; font-size: 14px;}

#ajax-contact-form *:focus::-webkit-input-placeholder {color:#4d4d4d; font-family: 'Lato', sans-serif; font-size: 14px;}
#ajax-contact-form *:focus::-moz-placeholder          {color:#4d4d4d; font-family: 'Lato', sans-serif; font-size: 14px;}/* Firefox 19+ */
#ajax-contact-form *:focus:-moz-placeholder           {color:#4d4d4d; font-family: 'Lato', sans-serif; font-size: 14px;}/* Firefox 18- */
#ajax-contact-form *:focus:-ms-input-placeholder      {color:#4d4d4d; font-family: 'Lato', sans-serif; font-size: 14px;}


#button_submit,
#fields .submit {
	font-family: 'Lato', sans-serif;
	font-size: 20px;
	margin: 0;
	color: #fff;
	text-decoration: none;
	background-image: linear-gradient(to right, #ad171f, #dd0f1a);
	position: relative;
	z-index: 10;
	display: inline-block;
 	padding: 6px 10px;
	border-radius: 20px;
	text-align: center;
	text-transform: uppercase;
	box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.16);
	border: none;
	@include break(medium) {
		font-size: 14px;
	}
	@include break(neutral) {
		max-width: 240px;
		margin-left: auto;
		margin-right: auto;
		float: none;
	}
	@include break(mobile) {
		line-height: 20px;
	}
	@include break(x-small) {
		font-size: 11px;
	}
	&:hover {
		box-shadow: none; 
	}
	&:active {
		top: 3px;
		color: #fff;
		background-image: linear-gradient(to right, #ad171f, #dd0f1a);
	}
	&:focus {
		opacity: .5;
		top: 3px;
		box-shadow: none;
		color: #fff;
		background-image: linear-gradient(to right, #ad171f, #dd0f1a);
	}
}

.notification_ok {
	padding-bottom: 15px;
	color: #ad171f;
	line-height: 1.5em;
	font-size: 24px;
	text-align: center;
	padding-top: 15px;
	a {
		text-decoration: none;
	}
}

.notification_error {
	color: #e54423;
	clear: both;
	text-align: left!important;
	font-size: 12px;
	line-height: 1.5em;
	font-weight: 700;
}
#note {
	clear: both;
	font-family: 'Lato', sans-serif;
}
#rc-imgelect, .g-recaptcha {
  width: 250px;
  transform:scale(0.82);
  transform-origin:0 0;
  float: left;
  margin-right: 15px;
	/*@include break(custom){
	  transform:scale(0.74);
	  transform-origin:0 0;
	}*/
	@include break(medium){
	  transform:scale(0.60);
	  transform-origin:0 0;
	}
	@include break(neutral){
	  transform:scale(0.56);
	  transform-origin:0 0;
	}
	@include break(mobile) {
		transform:scale(0.8);
	  transform-origin:0 0;
	}
	@include break(x-small){
		float: none;
	}

}
img {		height: auto;		max-width:100%;	}
.clearfix {	clear:both;}

#ajax-contact-form label.error {
	color: #f00;
	font-size: 12px;
	margin-top: -8px;
	display: inline-block;
	font-weight: 400;
	clear:both;
}

.mobile-none {
	@include break(neutral){
		display: none;
	}
}
div#button_submit {
	padding-left: 10px;
	padding-right: 10px;
	font-size: 16px;
	letter-spacing: 0;	
}


/**     
mobile menu   mobile menu    mobile menu   mobile menu      mobile menu        **/

//colors
$color_0: rgba(255, 255, 255, 0.75);
$color_btn: #222;
$color_text: #fff;
$color_shadow: #000;
$color_hover_bg: #ccc;
$color_hover_text: #222;

.slicknav_menu {
	font-size: 16px;
	box-sizing: border-box;
	background: #fff;
	display: none;
	z-index: 200;
	position: fixed;
	top: 0;
	width: 100%;
	left:0;
	height: 87px;
	border-top: 7px #ad171f solid;
	border-bottom: 1px solid #cccccc;
	transition: .3s ease;
	@include break(mobile){
		display: block;
	}
	.phone-mobile {
		@include fz(16px, 28px, #fff, 300);
		float: left;
		padding-left: 10px;
		padding-top: 6px;
		a {
			@include fz(16px, 28px, #fff, 300);
		}
		span {
			@include break(x-small){
				display: none;
			}
		}
	}
	* {		box-sizing: border-box;	}
	.slicknav_menutxt {
		color: $color_text;
		font-weight: bold;
		text-shadow: 0 1px 3px $color_shadow;
	}
	.slicknav_icon-bar {
		background-color: #f5841f;
	}
}
.slicknav_nav {
	color: $color_text;
	margin: 0;
	padding: 0;
	font-size: 0.875em;
	list-style: none;
	overflow: hidden;
	background: #f4f4f4;
	z-index:9999;
	top: 24px;
	position: relative;
	.slicknav_row {
		&:click,
		&:active,
		&:focus {
			color: #ffe49a;
			outline:none;
		}
	}
	li {
		&:hover {
			background-color: #e0e0e0;
		}
		a {
				text-align: left;
				color: #000;
				font-family: 'Lato', sans-serif;
				font-size: 18px;
				font-weight: 300;
				text-decoration: none;
				border-bottom: 1px #fff solid;
				padding-bottom: 0;
				text-transform: capitalize;			
				padding-left: 20px;
				padding-top: 8px;
				padding-bottom: 8px;
				@include link_trans();
				outline: none!important;
				&:hover {
					color: #ad171f;
				}
			& + i {
				@include break(mobile) {
					display: none;
				}
			}
		}
	}
	a.btn,
	a.btn:active,
	a.btn:focus {
		background: none;
		border:0;
		border-top: 1px rgba(255,255,255,.2) dashed;
		border-bottom: 1px rgba(255,255,255,.2) dashed;
		text-transform: capitalize;		
		border-radius: 0;
		padding-top: 0;
	}

	a > a {
		//border-top: 0;
	}
	.current-menu-item a {		color: #fff;	}
	.slicknav_txtnode {		margin-left: 15px;	}
	.slicknav_item a {		padding: 0;		margin: 0;	}
	.slicknav_parent-link a {		padding: 0;		margin: 0;	}
}
.slicknav_brand {
	color: $color_text;
	font-size: 18px;
	line-height: 30px;
	padding: 7px 12px;
	height: 44px;
}
.slicknav_btn {
	span {
		display: none;
	}
	display: block;
	margin: 0;
	padding: 0;
	text-decoration: none;
	border-radius: 0%;
	width: 60px;
	height: 52px;
	background: url("../img/mobile-button.png") 50% 0px no-repeat;
	background-size: 52px auto;
	float: left;
	margin: 0;
	margin-top: 4px;
	padding: 0;
	top: 10px;
	left:10px;
	position: relative;
	outline: none!important;
	@include link_trans();
	&.slicknav_open {
		background-position: 50% -50px;
	}
}

.slicknav_menu {
	*zoom: 1;
	.slicknav_menutxt {
		display: block;
		line-height: 1.188em;
		float: left;
	}
	.slicknav_icon {
		float: left;
    width: 1.125em;
    height: 0.875em;
		margin: 0.188em 0 0 0.438em;
        &:before {
            background: transparent;
            width: 1.125em;
            height: 0.875em;
            display: block;
            content: "";
            position: absolute;
        }
	}
	.slicknav_no-text {
		margin: 0;
	}
	.slicknav_icon-bar {
		display: block;
		width: 1.125em;
		height: 0.125em;
		-webkit-border-radius: 1px;
		-moz-border-radius: 1px;
		border-radius: 1px;
		-webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
		box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
	}
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.slicknav_nav {
	clear: both;
	ul {
		list-style: none;
		overflow: hidden;
		padding: 10px 0 10px 0;
		margin: 0;
		//background: #0a4490;
		li {
			a {
				text-transform: capitalize;
				line-height: 24px;
				color: #fff;
				border: none;
				font-size: 15px;
				font-weight: 400;
				padding-left: 30px;
				display: block;
				&:hover {
					color: #ffe49a;
				}
			}
		}
	}
	.slicknav_arrow {
		font-size: 0.8em;
		color: #ffe49a;
		font-family: 'FontAwesome';
		float: right;
		margin-right: 20px;
	}
	.slicknav_item {
		cursor: pointer;
		a {
			display: inline;
			border-bottom: none;
			&:click,
			&:active,
			&:focus {
				color: #ffe49a;
				outline:none;
			}

		}
	}
	.slicknav_row {
		display: block;
		&:click,
		&:active,
		&:focus {
			color: #fff;
			outline:none;
		}
	}
	a {		display: block;	}
	.slicknav_parent-link a {
		display: inline;
	}
}


/**     O T H E R       **/
a{color:#00215c; text-decoration:underline;}
a:focus{color:#00215c;text-decoration:none;}
a:hover{text-decoration:none;color:#000}
.center {	text-align: center;	}
.scrolloff {  pointer-events: none;}
.map {
	position: relative;
	padding-bottom: 65%;
	padding-top: 30px;
	height: 0;
	background: #ccc;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
	}
	@include break(neutral) {
		margin-bottom: 20px;
	}
}
ul.list {
	padding: 0 0 10px 10px;
	margin: 0;
	overflow: hidden;
	li {
		padding-left: 24px;
		position: relative;		
		padding-bottom: 14px;
		@include fz(18px, 1.34, #000, 300);
		font-family: $Roboto;
		@include break(mobile) {
			font-size: 16px;
		}	

	  &::after {
	  	width: 6px;
	  	height: 6px;
	  	position: absolute;
	  	left: 0px;
	  	top: 9px;
	  	border-radius: 50%;
	  	display: block;	  	
	  	background: #a43719;
	  	content: '';
	  	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
	  }
	}
}
.right {
	float: right;
	@include break(mobile) {
		float: none;
	}
}
.right_20 {
	margin-right: 25px;
}
.paddcontent { 
	padding-bottom: 20px;
	@include break(mobile) {
		padding-bottom: 10px;
	}	
}
.smallpadd {
	padding-bottom: 10px;
}

/* Buttons */
.btn:active, .btn:focus,
.btn {
		@include link_trans();
		@include fz(15px, 1, #141414, 700);
		margin: 0;
		font-family: 'Encode Sans', sans-serif;
		text-decoration: none;
		position: relative;
		z-index: 10;
		display: inline-block;
	 	padding: 10px 18px;
		border-radius: 20px;
		text-align: center;
		text-transform: uppercase;
		text-shadow:none;	
		background-image: linear-gradient(to right, #ad171f, #dd0f1a);
		box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.16);
		font-size: 14px;
		font-weight: 700;
		color: #fff;
		line-height: normal;
		@include break(custom) {

	}				
	@include break(medium) {

	}
	@include break(mobile) {
	}
	@include break(x-small) {
	}
	&:hover {
		box-shadow: none;
		color: #fff;
	}	
	&::after {
		content: "";
		font-family: 'simple-line-icons';
		display: inline-block;
		padding-left: 10px;
	}
	&:active {
		top: 3px;
	}
	&:focus {
		opacity: .5;
		top: 3px;
		box-shadow: none;
	}
}
hr {
	margin: 0 0 5px ;
	padding: 0;
	background: #d7d7d7;
	height: 1px;
	width: 100%;
	border: 0; 
}


.red {	color: #ba0003;}
a[href*="tel"]{text-decoration:none; cursor: default;};
::selection{background: #ededed!important;color:#000000};
::-moz-selection{background: #ededed!important;color:#000000};

/* OWL CAROUSEL */
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
	position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 60px;
    width: 40px;
}
.owl-carousel .owl-nav .owl-prev{
    left: 0;
    margin-left: -30px;
    @include break(mobile) {
    	margin-left: 10px;
    }
}
.owl-carousel .owl-nav .owl-next{
    right: 0;
    margin-right: -30px;
    img {
    	float: right;
    }
    @include break(mobile) {
    	margin-right: 10px;
    }
}