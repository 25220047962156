@charset "UTF-8";
/*
* Third Party
*/
@import url(../../bower_components/normalize.css/normalize.css);
/*
* Custom
*/
@import url(partials/reset.css);
@import url(partials/bootstrap.min.css);
@import url(partials/font-awesome.css);
@import url(partials/simple-line-icons.css);
@import url(partials/simple-line-icons.min.css);
@import url(partials/animate.css);
@import url(partials/owl.carousel.css);
@import url(partials/imagelightbox.css);
/* Fonts */
/* Mixins */
@import url("https://fonts.googleapis.com/css?family=Cinzel+Decorative:400,700|Cinzel:400,700|Roboto+Slab:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700");
@keyframes shake {
  0%, 100% {
    transform: translate3d(-10px, 0, 0); }
  50% {
    transform: translate3d(10px, 0, 0); } }

@keyframes shake2 {
  0%, 100% {
    margin-right: 0px; }
  50% {
    margin-right: -5px; } }

/* Fonts */
/*
font-family: $Cinzel;
font-family: $CinzelDecorative;
font-family: $Roboto;
*/
/* Media Screens */
html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; }

body {
  margin-top: 1px;
  font-size: 18px;
  line-height: 1.4;
  color: #292b2e;
  font-weight: 400;
  font-family: "Cinzel", serif;
  background: #fff;
  /*border-top: 7px solid #ad171f;*/ }
  @media (max-width: 1329px) {
    body {
      font-size: 15px; } }
  @media (max-width: 479px) {
    body {
      min-width: 320px;
      font-size: 14px; } }

.container {
  width: 1240px;
  margin: 0 auto;
  padding: 0; }
  @media (min-width: 1330px) {
    .container {
      width: 1170px; } }
  @media (max-width: 1329px) {
    .container {
      width: 990px;
      padding-left: 15px;
      padding-right: 15px; } }
  @media (max-width: 992px) {
    .container {
      width: 721px; } }
  @media (max-width: 767px) {
    .container {
      width: 100%;
      padding-left: 0;
      padding-right: 0; } }
  @media (max-width: 479px) {
    .container {
      width: 100%;
      min-width: 320px; } }

/**   nav menu  **/
#nav {
  z-index: 10;
  position: relative;
  float: left; }
  @media (max-width: 767px) {
    #nav {
      display: none; } }
  #nav:before, #nav:after {
    content: " ";
    display: table; }
  #nav:after {
    clear: both; }
  #nav .sf-menu {
    position: relative;
    font-size: 0;
    line-height: 0;
    white-space: nowrap;
    text-align: center;
    display: inline-block; }
    @media (max-width: 992px) {
      #nav .sf-menu {
        text-align: center;
        padding-right: 0;
        white-space: normal;
        padding-top: 2px;
        padding-bottom: 10px; } }
    #nav .sf-menu > li {
      margin: 0;
      padding: 0;
      position: relative;
      display: inline-block;
      font-size: 0;
      line-height: 0; }
      @media (max-width: 1600px) {
        #nav .sf-menu > li {
          padding-left: 8px;
          padding-right: 8px; } }
      @media (max-width: 1329px) {
        #nav .sf-menu > li {
          padding-left: 6px;
          padding-right: 6px; } }
      @media (max-width: 992px) {
        #nav .sf-menu > li {
          padding-left: 0px;
          padding-right: 0px;
          padding-top: 2px;
          padding-bottom: 2px; } }
      #nav .sf-menu > li > a {
        font-family: 'Encode Sans', sans-serif;
        color: #4d4d4d;
        font-size: 16px;
        line-height: 1.3;
        font-weight: 400;
        text-decoration: none;
        display: block;
        margin: 0;
        padding: 41px 20px 12px;
        transition: .2s ease-in-out;
        text-transform: uppercase;
        position: relative; }
        #nav .sf-menu > li > a:after {
          content: "";
          display: block;
          width: 50px;
          height: 1px;
          background: #d8d8d8;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%) scale(0);
          transition: .2s ease-in-out; }
        @media (max-width: 1329px) {
          #nav .sf-menu > li > a {
            font-size: 14px;
            padding-top: 25px;
            padding-left: 15px;
            padding-right: 15px; } }
        @media (max-width: 992px) {
          #nav .sf-menu > li > a {
            padding-left: 4px;
            padding-right: 4px;
            font-size: 13px;
            padding-top: 12px;
            padding-bottom: 2px; } }
      #nav .sf-menu > li.sfHover, #nav .sf-menu > li:hover {
        color: #ad171f; }
        #nav .sf-menu > li.sfHover > a, #nav .sf-menu > li:hover > a {
          color: #ad171f; }
          #nav .sf-menu > li.sfHover > a:after, #nav .sf-menu > li:hover > a:after {
            transform: translateX(-50%) scale(1); }
      #nav .sf-menu > li > a.current-menu-item {
        color: #000; }
        #nav .sf-menu > li > a.current-menu-item:hover {
          color: #ad171f; }
      #nav .sf-menu > li > a.sf-with-ul::before {
        content: '';
        display: block;
        float: right;
        font-family: "FontAwesome";
        font-size: 16px;
        color: #d7d7d7;
        margin-left: 6px;
        font-weight: 400;
        margin-right: -8px; }
        @media (max-width: 1329px) {
          #nav .sf-menu > li > a.sf-with-ul::before {
            display: none; } }
    @media (max-width: 992px) {
      #nav .sf-menu li + li {
        background: none; } }
    #nav .sf-menu .dd_menu {
      position: absolute;
      background: rgba(0, 0, 0, 0.9);
      min-width: 180px;
      display: none;
      text-align: left;
      border: 0;
      padding: 10px 10px;
      margin-top: 10px;
      border-radius: 0 0 5px 5px; }
      #nav .sf-menu .dd_menu li {
        display: block;
        position: relative; }
        #nav .sf-menu .dd_menu li a {
          font-size: 16px;
          line-height: 1.2;
          color: #ffe49a;
          font-weight: 400;
          text-decoration: none;
          border-left: none;
          padding: 4px 15px;
          display: block;
          letter-spacing: 0.5px;
          font-family: "Cinzel", serif; }
          #nav .sf-menu .dd_menu li a:hover {
            color: #fff; }
      #nav .sf-menu .dd_menu li + li {
        border-top: 1px #000000 solid; }

.sticky-wrapper {
  transition: 0.3s ease; }

/**     H E A D E R  **/
header.site-header {
  padding: 0;
  /*position: fixed;*/
  z-index: 1500;
  position: relative;
  background-color: #fff;
  border-top: 7px solid #ad171f;
  transition: 0.3s ease; }
  @media (max-width: 767px) {
    header.site-header {
      border-top: none; } }
  header.site-header.stuck {
    border-top-width: 3px;
    border-bottom: 1px solid #d8d8d8;
    position: fixed;
    top: 0;
    left: 0;
    right: 0; }
    header.site-header.stuck .slicknav_nav {
      position: 16px; }
    header.site-header.stuck .logo {
      padding-top: 10px;
      padding-bottom: 5px; }
      @media (max-width: 992px) {
        header.site-header.stuck .logo {
          padding-top: 15px;
          padding-bottom: 0; } }
      @media (max-width: 767px) {
        header.site-header.stuck .logo {
          padding-top: 5px; } }
    header.site-header.stuck p.phone {
      padding-top: 15px; }
      @media (max-width: 992px) {
        header.site-header.stuck p.phone {
          padding-top: 4px; } }
      @media (max-width: 767px) {
        header.site-header.stuck p.phone {
          padding-top: 0; } }
    header.site-header.stuck .btn {
      margin-top: 15px;
      margin-bottom: 15px; }
      @media (max-width: 992px) {
        header.site-header.stuck .btn {
          margin-top: 10px;
          margin-bottom: 10px; } }
    header.site-header.stuck #nav .sf-menu > li > a {
      padding-top: 30px; }
      @media (max-width: 1329px) {
        header.site-header.stuck #nav .sf-menu > li > a {
          padding-top: 20px; } }
      @media (max-width: 992px) {
        header.site-header.stuck #nav .sf-menu > li > a {
          padding-top: 12px; } }
  header.site-header > img {
    width: 100%; }
  header.site-header #wrapper {
    width: 100%;
    padding: 0;
    clear: both; }
    @media (max-width: 767px) {
      header.site-header #wrapper .facebook {
        display: none; } }
  header.site-header .logo {
    float: left;
    padding: 13px 31px 19px 46px;
    transition: 0.3s ease; }
    @media (max-width: 1329px) {
      header.site-header .logo {
        width: 174px;
        padding: 15px 0 0 15px; } }
    @media (max-width: 992px) {
      header.site-header .logo {
        width: 90px;
        padding-left: 10px;
        margin-right: 10px; } }
    @media (max-width: 767px) {
      header.site-header .logo {
        float: none;
        width: 190px;
        display: block;
        text-align: center;
        position: fixed;
        padding-top: 0;
        z-index: 1000;
        left: 50%;
        margin-left: -95px;
        margin-top: -4px; } }
    header.site-header .logo a {
      text-decoration: none;
      display: block; }
      header.site-header .logo a img {
        float: left; }
        @media (max-width: 992px) {
          header.site-header .logo a img {
            width: 90px; } }
        @media (max-width: 767px) {
          header.site-header .logo a img {
            width: 169px;
            display: block;
            margin: 16px auto;
            float: none;
            padding-right: 0; } }
  header.site-header .btn {
    float: right;
    margin: 32px 32px 31px 18px;
    transition: all .3s; }
    @media (max-width: 1329px) {
      header.site-header .btn {
        font-size: 12px;
        margin: 20px 20px 0 10px; } }
    @media (max-width: 992px) {
      header.site-header .btn {
        margin-top: 10px;
        margin-right: 10px;
        font-size: 10px; } }
  header.site-header .phone {
    float: right;
    padding-top: 31px;
    font-family: "Cinzel", serif;
    position: relative;
    transition: .3s ease; }
    @media (max-width: 1329px) {
      header.site-header .phone {
        padding-top: 20px;
        padding-bottom: 8px;
        padding-right: 30px; } }
    @media (max-width: 992px) {
      header.site-header .phone {
        padding-top: 4px;
        padding-right: 20px; } }
    @media (max-width: 767px) {
      header.site-header .phone {
        padding: 0;
        margin: 0;
        position: fixed;
        right: 24px;
        top: 20px;
        z-index: 500; } }
    header.site-header .phone span {
      display: block;
      padding: 0;
      text-transform: uppercase;
      font-family: 'Encode Sans', sans-serif;
      font-size: 15px;
      line-height: 15px;
      color: #000; }
      @media (max-width: 1329px) {
        header.site-header .phone span {
          font-size: 13px; } }
      @media (max-width: 992px) {
        header.site-header .phone span {
          font-size: 12px; } }
      @media (max-width: 767px) {
        header.site-header .phone span {
          display: none; } }
    header.site-header .phone a.tel {
      font-family: "Cinzel", serif;
      display: block;
      color: #000;
      font-size: 22px;
      line-height: 22px;
      font-weight: 700;
      font-family: 'Encode Sans', sans-serif; }
      header.site-header .phone a.tel:hover {
        cursor: pointer;
        opacity: .8; }
      @media (max-width: 1329px) {
        header.site-header .phone a.tel {
          font-size: 20px; } }
      @media (max-width: 992px) {
        header.site-header .phone a.tel {
          font-size: 18px; } }
      @media (max-width: 767px) {
        header.site-header .phone a.tel {
          height: 38px;
          width: 38px;
          line-height: 38px;
          text-align: center;
          border-radius: 50%;
          background-image: linear-gradient(to right, #ad171f, #dd0f1a);
          z-index: 1000;
          position: relative;
          font-size: 0; } }
      @media (max-width: 767px) {
        header.site-header .phone a.tel::before {
          content: "\f095";
          color: #fff;
          font-size: 20px;
          font-family: "FontAwesome";
          font-weight: 300; } }

/**    B R E A D C R U M B S      B R E A D C R U M B S         **/
.breadcrumbs {
  margin-bottom: -30px;
  padding-top: 30px; }
  @media (max-width: 767px) {
    .breadcrumbs {
      margin-bottom: 0;
      padding-top: 20px; } }
  .breadcrumbs ul {
    margin: 0;
    padding: 0; }
    .breadcrumbs ul li {
      float: left;
      font-size: 14px;
      line-height: 1.2;
      color: #9b9b9b;
      font-weight: 400;
      padding-right: 10px; }
      .breadcrumbs ul li span {
        font-family: "FontAwesome";
        color: #d8d8d8; }
      .breadcrumbs ul li a {
        text-decoration: none;
        color: #9b9b9b; }
        .breadcrumbs ul li a:hover {
          text-decoration: underline;
          color: #000; }

/**    S L I D E R         **/
#slider {
  /*padding-top: 108px;*/
  background: #1c1b19;
  /*	@include break(medium) {
		margin-top: 90px;
	}
	@include break(neutral) {
		margin-top: 66px;
	}
	@include break(mobile) {
		background: none;
		padding-top: 21px;
	}*/ }
  #slider:hover .flex-direction-nav {
    opacity: 1; }
  #slider > img {
    width: 100%; }
  #slider.no-nav {
    margin-bottom: 15px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5); }
    #slider.no-nav .flex-direction-nav {
      opacity: 0 !important; }

.flexslider {
  position: relative;
  overflow: hidden;
  background-color: #000; }
  @media (max-width: 767px) {
    .flexslider {
      background: none;
      padding: 0;
      margin-top: 86px; } }
  .flexslider .touch-indicator {
    background: url(//www.bobbittglass.com/wp-content/themes/glass/css/../images/icons/touch-indicator.png) 50% 50% no-repeat;
    height: 72px;
    width: 69px;
    margin-left: -35px;
    position: absolute;
    top: 35%;
    left: 50%;
    z-index: 10;
    animation: shake 2s infinite;
    transition: .5s; }
  .flexslider .disabled {
    opacity: 0;
    transition: .5s; }
  .flexslider ul.slides {
    overflow: hidden; }
    .flexslider ul.slides li {
      display: none; }
      .flexslider ul.slides li img {
        width: 100%;
        opacity: .7; }
        @media (max-width: 479px) {
          .flexslider ul.slides li img {
            max-width: 480px;
            width: 480px;
            position: relative;
            left: 50%;
            transform: translateX(-50%); } }
        @media (max-width: 767px) {
          .flexslider ul.slides li img {
            opacity: 1; } }
      .flexslider ul.slides li div {
        color: #fff;
        text-transform: uppercase;
        position: absolute;
        top: 50%;
        text-align: center;
        width: 100%;
        transform: translateY(-50%);
        text-shadow: 0 2px 1px rgba(0, 0, 0, 0.78);
        font-family: 'Encode Sans', sans-serif; }
        @media (max-width: 767px) {
          .flexslider ul.slides li div {
            position: relative;
            padding: 18px 18px 20px 24px;
            top: 0;
            transform: translateY(0); } }
        .flexslider ul.slides li div h1 {
          font-size: 50px;
          font-weight: 700;
          line-height: normal; }
          @media (max-width: 1329px) {
            .flexslider ul.slides li div h1 {
              font-size: 38px; } }
          @media (max-width: 992px) {
            .flexslider ul.slides li div h1 {
              font-size: 28px; } }
          @media (max-width: 767px) {
            .flexslider ul.slides li div h1 {
              font-size: 18px;
              margin-bottom: 10px;
              margin-top: 0; } }
        .flexslider ul.slides li div p {
          font-size: 41px;
          font-weight: 400;
          line-height: normal; }
          @media (max-width: 1329px) {
            .flexslider ul.slides li div p {
              font-size: 34px; } }
          @media (max-width: 992px) {
            .flexslider ul.slides li div p {
              font-size: 24px; } }
          @media (max-width: 767px) {
            .flexslider ul.slides li div p {
              font-size: 15px;
              margin-bottom: 0; } }
    @media (max-width: 767px) {
      .sub-page .flexslider ul.slides li img {
        padding-bottom: 0; } }
  .flexslider .flex-control-nav.flex-control-paging {
    position: absolute;
    bottom: 20px;
    width: 100%;
    left: 0;
    z-index: 101;
    text-align: center; }
    @media (max-width: 992px) {
      .flexslider .flex-control-nav.flex-control-paging {
        bottom: 6px; } }
    @media (max-width: 767px) {
      .flexslider .flex-control-nav.flex-control-paging {
        display: none; } }
    .flexslider .flex-control-nav.flex-control-paging li {
      display: inline-block;
      text-indent: -9999px;
      padding: 0 10px; }
      .flexslider .flex-control-nav.flex-control-paging li a {
        display: block;
        /*background-color: rgba(255, 228, 154, 0.6);*/
        background-color: rgba(6, 6, 6, 0.21);
        /*box-shadow: 0 2px 4px 0 rgba(6, 2, 0, 0.32);	*/
        /*opacity: 1;*/
        opacity: 1;
        /*width: 10px;
	  			height: 10px;*/
        width: 9px;
        height: 9px;
        border-radius: 50%;
        border: 1px #fff solid;
        position: relative; }
        .flexslider .flex-control-nav.flex-control-paging li a:hover, .flexslider .flex-control-nav.flex-control-paging li a.flex-active {
          background-color: rgba(6, 6, 6, 0.21);
          /*background: #282828;*/
          /*border-color:#fffbe7;*/
          border: 1px #fff solid;
          /*opacity: 1;*/
          /*width: 14px;
		  			height: 14px;*/
          width: 13px;
          height: 13px;
          /*box-shadow: 0 2px 8px 0 rgba(6, 2, 0, 0.8);	*/
          margin-top: -2px;
          margin-left: -2px;
          margin-right: -2px; }
          .flexslider .flex-control-nav.flex-control-paging li a:hover:before, .flexslider .flex-control-nav.flex-control-paging li a.flex-active:before {
            content: " ";
            display: block;
            width: 9px;
            height: 9px;
            left: 1px;
            top: 1px;
            border-radius: 50%;
            background-color: #fff;
            position: absolute; }
  .flexslider .wrapper-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 1001;
    left: 0;
    transform: translateY(-50%); }
    @media (max-width: 767px) {
      .flexslider .wrapper-nav {
        display: none; } }
    .flexslider .wrapper-nav .flex-direction-nav {
      opacity: 0;
      transition: .2s ease-in-out;
      padding: 5px; }
      .flexslider .wrapper-nav .flex-direction-nav li {
        float: left;
        margin-left: 33px; }
        .flexslider .wrapper-nav .flex-direction-nav li a {
          display: block;
          background-image: url("../img/slide-arrow-left.png");
          width: 17px;
          height: 58px;
          font-size: 0;
          text-decoration: none;
          outline: none; }
          .flexslider .wrapper-nav .flex-direction-nav li a:hover {
            opacity: .88; }
      .flexslider .wrapper-nav .flex-direction-nav li + li {
        float: right;
        margin-right: 33px; }
        .flexslider .wrapper-nav .flex-direction-nav li + li a {
          background-image: url("../img/slide-arrow-right.png");
          font-size: 0; }
  .flexslider .caption {
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -80px; }
    @media (max-width: 1329px) {
      .flexslider .caption {
        bottom: 10px; } }
    @media (max-width: 767px) {
      .flexslider .caption {
        position: relative;
        top: 0;
        transform: translateY(0);
        left: 0;
        margin-left: 0;
        text-align: center;
        background: none;
        padding-top: 18px; } }
    @media (max-width: 479px) {
      .flexslider .caption {
        min-height: 55px;
        padding-top: 0;
        margin-top: 10px;
        bottom: 0; } }
    .flexslider .caption p {
      font-size: 40px;
      line-height: 1.25;
      color: #ffe49a;
      font-weight: 400;
      display: inline-block;
      padding: 140px 0 0 120px;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.85);
      letter-spacing: 1px;
      font-family: "Cinzel", serif;
      position: relative;
      max-width: 540px; }
      .flexslider .caption p::after {
        content: url(../img/moo.png);
        position: absolute;
        bottom: 0;
        display: block;
        left: 0; }
        @media (max-width: 992px) {
          .flexslider .caption p::after {
            display: none; } }
      @media (max-width: 1329px) {
        .flexslider .caption p {
          max-width: 480px;
          font-size: 32px; } }
      @media (max-width: 992px) {
        .flexslider .caption p {
          font-size: 24px;
          max-width: 380px; } }
      @media (max-width: 767px) {
        .flexslider .caption p {
          padding: 0;
          background: none;
          margin-bottom: 0;
          font-size: 18px; }
          .flexslider .caption p br {
            display: none; } }
      @media (max-width: 479px) {
        .flexslider .caption p {
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          left: 0;
          width: 100%;
          font-size: 24px;
          padding-left: 12px;
          padding-right: 12px; } }

div.slogan {
  position: relative; }
  div.slogan .inner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 100%; }
  @media (max-width: 767px) {
    div.slogan img {
      max-width: 1080px;
      width: 1080px;
      position: relative;
      left: -340px; } }
  @media (max-width: 479px) {
    div.slogan img {
      left: -480px; } }
  div.slogan h1 {
    font-size: 40px;
    line-height: 1.25;
    color: #f5e8a7;
    font-weight: 400;
    margin: 0;
    padding: 0 0 20px 0; }
    @media (max-width: 1329px) {
      div.slogan h1 {
        font-size: 36px;
        padding-bottom: 10px; } }
    @media (max-width: 992px) {
      div.slogan h1 {
        font-size: 24px;
        padding-bottom: 2px; } }
  div.slogan h3 {
    font-size: 24px;
    line-height: 1.25;
    color: #fffbe7;
    font-weight: 300;
    font-family: "Roboto Slab", serif;
    max-width: 680px;
    margin-left: auto;
    margin-right: auto; }
    @media (max-width: 1329px) {
      div.slogan h3 {
        font-size: 20px;
        max-width: 570px; } }
    @media (max-width: 992px) {
      div.slogan h3 {
        font-size: 16px;
        padding-top: 12px;
        margin-top: 0;
        max-width: 400px; } }
    @media (max-width: 767px) {
      div.slogan h3 {
        font-size: 18px;
        padding-left: 15px;
        padding-right: 15px;
        line-height: 1.4; } }
  div.slogan hr {
    display: block;
    padding: 0;
    margin: 0;
    background: url("../img/line.png") 50% 50% no-repeat;
    border: 0;
    height: 22px;
    width: 100%;
    display: block; }

/**     C O N T E N T          **/
section.bg-grey {
  background-color: #f2f2f2;
  padding-top: 38px;
  padding-bottom: 130px; }
  @media (max-width: 1329px) {
    section.bg-grey {
      padding-bottom: 100px; } }
  @media (max-width: 992px) {
    section.bg-grey {
      padding-top: 0;
      padding-bottom: 122px; } }
  @media (max-width: 767px) {
    section.bg-grey {
      padding-top: 24px;
      padding-left: 22px;
      padding-right: 20px;
      padding-bottom: 165px;
      text-align: center; } }
  section.bg-grey h2 {
    font-family: 'Encode Sans', sans-serif;
    font-size: 30px;
    font-weight: 300;
    color: #000;
    max-width: 760px;
    float: left;
    line-height: normal; }
    @media (max-width: 1329px) {
      section.bg-grey h2 {
        font-size: 26px; } }
    @media (max-width: 992px) {
      section.bg-grey h2 {
        max-width: 530px;
        font-size: 21px; } }
    @media (max-width: 767px) {
      section.bg-grey h2 {
        font-size: 24px;
        margin-top: 0;
        margin-bottom: 20px;
        max-width: 100%; } }
  section.bg-grey a.btn {
    float: right;
    margin-top: 55px;
    margin-right: 10px; }
    @media (max-width: 1329px) {
      section.bg-grey a.btn {
        font-size: 12px; } }
    @media (max-width: 992px) {
      section.bg-grey a.btn {
        font-size: 10px;
        margin-top: 40px;
        margin-right: 0; } }
    @media (max-width: 767px) {
      section.bg-grey a.btn {
        float: none;
        font-size: 14px;
        margin: 0 auto;
        font-weight: 300; } }
  section.bg-grey .hr {
    border-top: 1px solid #979797;
    margin-top: 19px; }
    @media (max-width: 767px) {
      section.bg-grey .hr {
        margin-top: 42px;
        margin-left: -22px;
        margin-right: -20px; } }
  section.bg-grey h3 {
    font-family: 'Encode Sans', sans-serif;
    font-size: 28px;
    font-weight: 700;
    color: #000;
    margin-top: 22px;
    margin-bottom: 10px;
    line-height: normal; }
    @media (max-width: 992px) {
      section.bg-grey h3 {
        font-size: 24px;
        margin-top: 18px; } }
    @media (max-width: 767px) {
      section.bg-grey h3 {
        margin-top: 41px;
        font-size: 26px;
        font-stretch: condensed; } }

.sub-page {
  /**    S L I D E R         **/ }
  .sub-page .flexslider ul.slides {
    max-height: 350px; }
    .sub-page .flexslider ul.slides div {
      display: none; }

section.bg-white {
  background: url("../img/bitmap.jpg") no-repeat right bottom #fff;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 87px;
  /*h5 {
		@include fz(18px, 1.25, #1b0b01, 700);	
		font-family: $Roboto;
		margin: 0;
		padding: 0 0 15px 0;
		@include break(custom) {
			font-size: 18px;
		}
		@include break(neutral) {
			font-size: 16px;
		}
	}	
	h6 {
		@include fz(16px, 1.2, #a43719, 400);	
		font-family: $Roboto;
		margin: 0 0 4px 0;
		padding: 0 0 15px 0;
		@include break(custom) {
			font-size: 16px;
		}
		@include break(neutral) {
			font-size: 14px;
		}

	}	*/ }
  @media (max-width: 1329px) {
    section.bg-white {
      padding-bottom: 60px; } }
  @media (max-width: 767px) {
    section.bg-white {
      background-size: contain;
      padding-bottom: 72px; } }
  .sub-page section.bg-white {
    padding-top: 45px;
    padding-bottom: 200px;
    min-height: 300px;
    background-size: 50%; }
    @media (max-width: 992px) {
      .sub-page section.bg-white {
        background-size: 50%; } }
    @media (max-width: 767px) {
      .sub-page section.bg-white {
        background-size: contain; } }
    .sub-page section.bg-white h1 {
      position: relative;
      text-transform: uppercase; }
      @media (max-width: 767px) {
        .sub-page section.bg-white h1 {
          text-align: center; } }
    .sub-page section.bg-white .contacts-phone-address h6 {
      margin-top: 0;
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 20px;
      text-align: justify; }
      @media (max-width: 767px) {
        .sub-page section.bg-white .contacts-phone-address h6 {
          padding-left: 0; } }
    .sub-page section.bg-white .contacts-phone-address p {
      font-family: 'Lato', sans-serif;
      font-size: 16px;
      color: #000000; }
    .sub-page section.bg-white .one-block + .one-block {
      border-top: 1px solid #979797;
      padding-top: 34px; }
    .sub-page section.bg-white .one-block h2 {
      padding-bottom: 10px; }
      @media (max-width: 1329px) {
        .sub-page section.bg-white .one-block h2 {
          font-size: 26px; } }
      @media (max-width: 992px) {
        .sub-page section.bg-white .one-block h2 {
          font-size: 22px; } }
      @media (max-width: 767px) {
        .sub-page section.bg-white .one-block h2 {
          text-align: center; } }
    .sub-page section.bg-white .one-block h3 {
      font-size: 30px;
      padding-top: 0;
      font-weight: 300; }
      @media (max-width: 1329px) {
        .sub-page section.bg-white .one-block h3 {
          font-size: 28px; } }
      @media (max-width: 992px) {
        .sub-page section.bg-white .one-block h3 {
          font-size: 24px;
          padding-bottom: 10px; } }
    .sub-page section.bg-white .one-block p + p {
      margin-bottom: 35px; }
    .sub-page section.bg-white .one-block p {
      text-align: justify; }
      @media (max-width: 992px) {
        .sub-page section.bg-white .one-block p {
          font-size: 16px; } }
      @media (max-width: 767px) {
        .sub-page section.bg-white .one-block p {
          font-size: 16px;
          padding-left: 29px;
          padding-right: 29px; } }
    .sub-page section.bg-white .items {
      margin-top: 0; }
      @media (max-width: 992px) {
        .sub-page section.bg-white .items .item p {
          font-size: 14px;
          margin: 0px 5px 0 5px; } }
      @media (max-width: 992px) {
        .sub-page section.bg-white .items .item {
          min-height: 197px; } }
    .sub-page section.bg-white .what-we-do-block h1 {
      font-size: 40px; }
      @media (max-width: 1329px) {
        .sub-page section.bg-white .what-we-do-block h1 {
          font-size: 34px; } }
      @media (max-width: 992px) {
        .sub-page section.bg-white .what-we-do-block h1 {
          font-size: 28px; } }
      @media (max-width: 767px) {
        .sub-page section.bg-white .what-we-do-block h1 {
          text-align: center; } }
    .sub-page section.bg-white .what-we-do-block p {
      padding-top: 10px;
      padding-bottom: 15px; }
      @media (max-width: 767px) {
        .sub-page section.bg-white .what-we-do-block p {
          padding-left: 29px;
          padding-right: 29px; } }
    .sub-page section.bg-white .what-we-do-block h2 {
      padding-bottom: 20px; }
      @media (max-width: 767px) {
        .sub-page section.bg-white .what-we-do-block h2 {
          text-align: center; } }
  section.bg-white .items {
    display: flex;
    flex-direction: row;
    margin-top: -190px; }
    @media (max-width: 1329px) {
      section.bg-white .items {
        margin-top: -150px; } }
    @media (max-width: 992px) {
      section.bg-white .items {
        margin-top: -180px; } }
    @media (max-width: 767px) {
      section.bg-white .items {
        margin-top: -245px; } }
    section.bg-white .items .item {
      width: 20%;
      border: 1px solid #d5d5d5;
      padding: 6px; }
      @media (max-width: 992px) {
        section.bg-white .items .item {
          width: 100%;
          min-height: 240px; } }
      @media (max-width: 767px) {
        section.bg-white .items .item {
          width: 290px;
          padding: 7px;
          margin: 0 auto; } }
      section.bg-white .items .item p {
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        color: #000000;
        margin: 21px 14px 6px 13px;
        line-height: normal; }
        @media (max-width: 1329px) {
          section.bg-white .items .item p {
            font-size: 14px;
            margin: 10px 5px 0 5px; } }
        @media (max-width: 767px) {
          section.bg-white .items .item p {
            font-size: 16px;
            margin: 17px 18px 10px 16px; } }
    section.bg-white .items .item + .item {
      margin-left: 16px; }
  section.bg-white h1 {
    font-size: 40px;
    line-height: 1.25;
    color: #000;
    font-weight: 700;
    font-family: 'Encode Sans', sans-serif;
    margin: 0;
    padding: 0 0 15px 0; }
    @media (max-width: 1329px) {
      section.bg-white h1 {
        font-size: 30px; } }
    @media (max-width: 992px) {
      section.bg-white h1 {
        font-size: 24px; } }
    @media (max-width: 767px) {
      section.bg-white h1 {
        font-size: 30px; } }
  section.bg-white h2 {
    font-size: 28px;
    line-height: 1.25;
    color: #000;
    font-weight: 700;
    font-family: 'Encode Sans', sans-serif;
    margin: 0;
    padding: 0 0 15px 0; }
    @media (max-width: 1329px) {
      section.bg-white h2 {
        font-size: 22px; } }
    @media (max-width: 992px) {
      section.bg-white h2 {
        font-size: 16px; } }
    @media (max-width: 767px) {
      section.bg-white h2 {
        font-size: 26px; } }
    @media (max-width: 479px) {
      section.bg-white h2 {
        font-size: 18px; } }
  section.bg-white h3 {
    font-size: 28px;
    line-height: 1.25;
    color: #000;
    font-weight: 700;
    font-family: 'Encode Sans', sans-serif;
    margin: 0;
    padding: 58px 0 15px 0; }
    @media (max-width: 1329px) {
      section.bg-white h3 {
        font-size: 22px; } }
    @media (max-width: 992px) {
      section.bg-white h3 {
        font-size: 16px;
        padding-top: 40px;
        padding-bottom: 0; } }
    @media (max-width: 767px) {
      section.bg-white h3 {
        padding-top: 56px;
        font-size: 28px;
        text-align: center; } }
  section.bg-white h4 {
    font-size: 20px;
    line-height: 1.25;
    color: #a43719;
    font-weight: 400;
    font-family: "Roboto Slab", serif;
    margin: 0;
    padding: 0 0 15px 0; }
    @media (max-width: 1600px) {
      section.bg-white h4 {
        font-size: 20px; } }
    @media (max-width: 992px) {
      section.bg-white h4 {
        font-size: 16px; } }
  section.bg-white h5 {
    font-family: 'Encode Sans', sans-serif;
    font-size: 20px;
    line-height: 1.25;
    color: #000;
    font-weight: 300;
    max-width: 803px; }
    @media (max-width: 1329px) {
      section.bg-white h5 {
        font-size: 18px;
        max-width: 710px; } }
    @media (max-width: 992px) {
      section.bg-white h5 {
        font-size: 16px;
        max-width: 500px; } }
    @media (max-width: 767px) {
      section.bg-white h5 {
        padding-left: 29px;
        padding-right: 22px;
        margin-top: 31px;
        font-size: 20px;
        max-width: 100%; } }
  section.bg-white h6 {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    line-height: 1.25;
    color: #000;
    font-weight: 300;
    margin-bottom: 35px;
    max-width: 803px; }
    @media (max-width: 1329px) {
      section.bg-white h6 {
        font-size: 16px;
        max-width: 710px; } }
    @media (max-width: 992px) {
      section.bg-white h6 {
        font-size: 14px;
        margin-bottom: 50px;
        max-width: 500px; } }
    @media (max-width: 767px) {
      section.bg-white h6 {
        padding-left: 29px;
        padding-right: 22px;
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 20px;
        max-width: 100%; } }
  section.bg-white p {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    line-height: 1.25;
    color: #000;
    font-weight: 500; }
  section.bg-white .btn {
    border: solid 1px #dadada;
    background: transparent;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #181818;
    box-shadow: none;
    text-transform: none; }
    section.bg-white .btn:hover {
      background-color: #eeeeee;
      color: #181818; }
    section.bg-white .btn:active {
      background-color: #dddddd; }
    section.bg-white .btn:focus {
      background-color: #dddddd;
      opacity: 1; }
    section.bg-white .btn::after {
      content: "\f105";
      font-family: 'Fontawesome';
      display: inline-block;
      padding-left: 10px;
      color: #9b9b9b;
      font-weight: 400; }
    @media (max-width: 1329px) {
      section.bg-white .btn {
        font-size: 12px; } }
    @media (max-width: 992px) {
      section.bg-white .btn {
        font-size: 10px; } }
    @media (max-width: 767px) {
      section.bg-white .btn {
        padding-left: 16px;
        font-size: 14px;
        margin-left: 34px; } }

@media (max-width: 992px) {
  section.bg-white.contacts-bg {
    padding-bottom: 30px; } }

@media (max-width: 767px) {
  section.bg-white.contacts-bg {
    padding-left: 29px;
    padding-right: 29px; } }

section.bg-white.contacts-bg .contacts-bg-content h1 {
  font-size: 48px; }
  @media (max-width: 1329px) {
    section.bg-white.contacts-bg .contacts-bg-content h1 {
      font-size: 48px; } }
  @media (max-width: 992px) {
    section.bg-white.contacts-bg .contacts-bg-content h1 {
      font-size: 36px; } }

section.bg-white.contacts-bg .contacts-bg-content img {
  width: 100%; }
  @media (max-width: 767px) {
    section.bg-white.contacts-bg .contacts-bg-content img {
      width: auto;
      padding-bottom: 20px; } }

section.bg-white.contacts-bg .contacts-bg-content h2 {
  padding-top: 75px;
  padding-bottom: 20px;
  font-size: 36px; }
  @media (max-width: 1329px) {
    section.bg-white.contacts-bg .contacts-bg-content h2 {
      font-size: 36px; } }
  @media (max-width: 992px) {
    section.bg-white.contacts-bg .contacts-bg-content h2 {
      font-size: 34px; } }

/* S I D E B A R   */
.sidebar {
  padding-right: 20px; }

/* G A L L E R Y */
a.gallery {
  display: block;
  border: 1px #7e5d47 solid;
  padding: 4px;
  background: #ffe49a;
  margin-bottom: 20px;
  margin-top: 10px;
  position: relative;
  text-decoration: none;
  border-radius: 4px;
  width: 48%;
  float: left;
  margin-right: 2%; }
  a.gallery::before {
    opacity: 0;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.15s ease;
    -moz-transition: all 0.15s ease;
    -ms-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease;
    background: rgba(255, 255, 255, 0.2); }
  a.gallery::after {
    opacity: 0;
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 50px;
    -webkit-transition: all 0.15s ease;
    -moz-transition: all 0.15s ease;
    -ms-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease;
    font-family: 'FontAwesome';
    text-align: center;
    color: #fff;
    font-size: 30px;
    transform: translate(-50%, -50%); }
  a.gallery:hover::before, a.gallery:hover::after {
    opacity: 1; }
  a.gallery img {
    width: 100%; }
  a.gallery p {
    text-align: center;
    padding-top: 15px;
    color: #a43719;
    font-size: 20px; }

a.facebook {
  font-size: 0;
  float: right;
  margin-top: 25px;
  display: block;
  border-radius: 4px;
  overflow: hidden; }
  @media (max-width: 992px) {
    a.facebook {
      margin-top: 0; } }
  a.facebook img {
    display: block;
    padding: 0;
    margin: 0; }

/**     F O O T E R        F O O T E R         F O O T E R         **/
footer.site-footer {
  padding: 66px 32px 47px 50px;
  overflow: hidden; }
  @media (max-width: 1329px) {
    footer.site-footer {
      padding: 25px 15px; } }
  @media (max-width: 992px) {
    footer.site-footer {
      padding: 20px 10px; } }
  @media (max-width: 767px) {
    footer.site-footer {
      background-color: #fff;
      padding: 31px 36px 39px 44px; } }
  footer.site-footer .footer-address, footer.site-footer .footer-phone {
    float: left;
    position: relative;
    margin-right: 18%; }
    @media (max-width: 1329px) {
      footer.site-footer .footer-address, footer.site-footer .footer-phone {
        margin-right: 10%; } }
    @media (max-width: 992px) {
      footer.site-footer .footer-address, footer.site-footer .footer-phone {
        margin-left: 50px; } }
    @media (max-width: 767px) {
      footer.site-footer .footer-address, footer.site-footer .footer-phone {
        float: none;
        margin-left: 0; } }
    footer.site-footer .footer-address div, footer.site-footer .footer-phone div {
      margin-bottom: 18px; }
      @media (max-width: 1329px) {
        footer.site-footer .footer-address div, footer.site-footer .footer-phone div {
          margin-bottom: 5px; } }
      @media (max-width: 767px) {
        footer.site-footer .footer-address div, footer.site-footer .footer-phone div {
          margin-bottom: 19px; } }
    footer.site-footer .footer-address i, footer.site-footer .footer-phone i {
      float: left;
      padding-right: 8px;
      color: #cecece;
      font-size: 18px; }
      @media (max-width: 1329px) {
        footer.site-footer .footer-address i, footer.site-footer .footer-phone i {
          font-size: 16px; } }
      @media (max-width: 992px) {
        footer.site-footer .footer-address i, footer.site-footer .footer-phone i {
          font-size: 14px; } }
      @media (max-width: 767px) {
        footer.site-footer .footer-address i, footer.site-footer .footer-phone i {
          font-size: 18px; } }
    footer.site-footer .footer-address h6, footer.site-footer .footer-phone h6 {
      margin: 0;
      text-transform: uppercase;
      font-family: 'Lato', sans-serif;
      font-size: 14px;
      color: #7c7c7c; }
      @media (max-width: 1329px) {
        footer.site-footer .footer-address h6, footer.site-footer .footer-phone h6 {
          font-size: 12px; } }
      @media (max-width: 992px) {
        footer.site-footer .footer-address h6, footer.site-footer .footer-phone h6 {
          font-size: 10px; } }
      @media (max-width: 767px) {
        footer.site-footer .footer-address h6, footer.site-footer .footer-phone h6 {
          font-size: 14px; } }
    footer.site-footer .footer-address p, footer.site-footer .footer-phone p {
      font-family: 'Lato', sans-serif;
      font-size: 16px;
      color: #181818; }
      @media (max-width: 1329px) {
        footer.site-footer .footer-address p, footer.site-footer .footer-phone p {
          font-size: 14px; } }
      @media (max-width: 992px) {
        footer.site-footer .footer-address p, footer.site-footer .footer-phone p {
          font-size: 12px;
          min-width: 160px; } }
      @media (max-width: 767px) {
        footer.site-footer .footer-address p, footer.site-footer .footer-phone p {
          font-size: 16px; } }
    footer.site-footer .footer-address .tel, footer.site-footer .footer-phone .tel {
      font-family: 'Lato', sans-serif;
      font-size: 24px;
      font-weight: 700;
      color: #181818; }
      @media (max-width: 1329px) {
        footer.site-footer .footer-address .tel, footer.site-footer .footer-phone .tel {
          font-size: 20px; } }
      @media (max-width: 992px) {
        footer.site-footer .footer-address .tel, footer.site-footer .footer-phone .tel {
          font-size: 16px; } }
      @media (max-width: 767px) {
        footer.site-footer .footer-address .tel, footer.site-footer .footer-phone .tel {
          font-size: 24px; } }
  footer.site-footer .footer-phone {
    margin: 0; }
  footer.site-footer .footer-logo {
    float: left;
    margin-right: 14%; }
    @media (max-width: 1329px) {
      footer.site-footer .footer-logo {
        margin-right: 10%; } }
    @media (max-width: 992px) {
      footer.site-footer .footer-logo {
        margin-right: 20px; } }
    @media (max-width: 767px) {
      footer.site-footer .footer-logo {
        float: none;
        display: block;
        text-align: center; } }
    footer.site-footer .footer-logo a {
      max-width: 190px;
      text-align: center;
      text-decoration: none;
      display: block; }
      @media (max-width: 1329px) {
        footer.site-footer .footer-logo a {
          max-width: 160px; } }
      @media (max-width: 992px) {
        footer.site-footer .footer-logo a {
          max-width: 140px; } }
      @media (max-width: 767px) {
        footer.site-footer .footer-logo a {
          width: 170px;
          text-align: left;
          margin-bottom: 27px; }
          footer.site-footer .footer-logo a img {
            max-width: 170px; } }
  footer.site-footer .btn {
    float: right; }
    @media (max-width: 1329px) {
      footer.site-footer .btn {
        font-size: 12px; } }
    @media (max-width: 992px) {
      footer.site-footer .btn {
        font-size: 10px; } }
    @media (max-width: 767px) {
      footer.site-footer .btn {
        float: none;
        font-size: 14px;
        margin-top: 39px;
        width: 100%;
        max-width: 295px; } }

/*-------------------    Copyright  --------------------*/
.copyright {
  padding: 26px 33px 13px 50px;
  border-top: 7px solid #eee; }
  @media (max-width: 767px) {
    .copyright {
      background-color: #fff;
      padding: 26px 42px; } }
  .copyright .footer-logo-right {
    margin-top: -40px;
    margin-left: 38px;
    width: 84px;
    height: 74px; }
    @media (max-width: 767px) {
      .copyright .footer-logo-right {
        float: none !important;
        margin: 0 auto;
        display: block; } }
    @media (max-width: 767px) {
      .copyright .footer-logo-right img {
        float: none; } }
  .copyright p {
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    color: #8a8a8a;
    margin: 0;
    line-height: normal; }
    @media (max-width: 1329px) {
      .copyright p {
        font-size: 14px; } }
    @media (max-width: 992px) {
      .copyright p {
        font-size: 12px; } }
    @media (max-width: 767px) {
      .copyright p {
        float: none !important;
        width: 100%;
        text-align: center;
        font-size: 15px;
        margin-bottom: 15px; } }
    .copyright p a {
      font-weight: 700;
      text-decoration: underline;
      color: #8a8a8a;
      vertical-align: baseline; }
      .copyright p a:hover {
        text-decoration: none; }
      .copyright p a img {
        float: right;
        margin-top: -40px;
        padding-left: 38px; }
        @media (max-width: 767px) {
          .copyright p a img {
            margin-top: -20px;
            float: none; } }
        @media (max-width: 479px) {
          .copyright p a img {
            margin-top: 10px;
            float: none;
            padding-left: 0;
            margin-left: auto;
            margin-right: auto; } }

/* C O N D I T I O N A L    L O G I C */
#ajax-contact-form .div_select > p {
  border: 4px #979797 solid;
  padding: 10px;
  display: none; }
  #ajax-contact-form .div_select > p strong {
    display: block;
    margin-left: 5px;
    padding-bottom: 10px; }
  #ajax-contact-form .div_select > p b {
    display: block;
    background: #cb1325;
    color: #fff;
    font-size: 16px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 6px;
    margin-top: 4px;
    font-weight: 400;
    display: block;
    display: none; }
  #ajax-contact-form .div_select > p input[type="checkbox"] {
    background: #fffdf4;
    border: 1px #979797 solid;
    width: 15px;
    height: 15px;
    margin-right: 12px;
    float: left;
    margin-bottom: 6px; }
  #ajax-contact-form .div_select > p label {
    font-size: 16px;
    line-height: 1.2;
    color: #24130d;
    font-weight: 400;
    overflow: hidden;
    width: 100%;
    margin-left: 5px;
    margin-bottom: 0; }

/**     F O R M    F O R M    F O R M    F O R M    F O R M    F O R M     **/
#ajax-contact-form {
  text-align: left;
  background-color: #ebebeb;
  padding: 30px 40px; }
  @media (max-width: 1329px) {
    #ajax-contact-form {
      padding: 10px 20px; } }
  @media (max-width: 992px) {
    #ajax-contact-form {
      width: 100%; } }
  @media (max-width: 767px) {
    #ajax-contact-form {
      padding-bottom: 50px; } }
  #ajax-contact-form h3 {
    padding-top: 0;
    text-transform: uppercase;
    text-align: center; }
    @media (max-width: 1329px) {
      #ajax-contact-form h3 {
        padding-top: 20px;
        padding-bottom: 10px; } }
  #ajax-contact-form h3 + p {
    padding-bottom: 30px;
    text-align: center; }
    @media (max-width: 1329px) {
      #ajax-contact-form h3 + p {
        padding-bottom: 25px; } }
  #ajax-contact-form p {
    font-family: 'Encode Sans', sans-serif;
    margin-bottom: 0;
    text-align: left;
    font-size: 16px; }
    #ajax-contact-form p.middle {
      line-height: 3; }
    #ajax-contact-form p.select {
      position: relative; }
    #ajax-contact-form p input {
      padding: 10px;
      font-size: 16px; }
  @media (max-width: 767px) {
    #ajax-contact-form p:last-child {
      text-align: center; } }
  #ajax-contact-form .signature {
    width: 240px; }

#ajax-contact-form textarea,
#ajax-contact-form select,
#ajax-contact-form input {
  padding: 4px 12px 6px;
  background: #fff;
  width: 100%;
  border-radius: 0px;
  font-family: 'Lato', sans-serif;
  color: #000;
  font-size: 14px;
  margin: 4px 0 12px;
  border: solid 1px #979797; }

#ajax-contact-form select {
  font-size: 14px;
  padding-left: 8px;
  height: 36px;
  color: #000; }

#ajax-contact-form textarea:active,
#ajax-contact-form input:active,
#ajax-contact-form select:active,
#ajax-contact-form select:focus,
#ajax-contact-form textarea:focus,
#ajax-contact-form input:focus {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #979797;
  background: #fffdf4;
  color: #000;
  outline: none; }

#ajax-contact-form textarea {
  height: 132px;
  max-width: 100%; }

#ajax-contact-form *::-webkit-input-placeholder {
  color: #4d4d4d;
  font-family: 'Lato', sans-serif;
  font-size: 14px; }

#ajax-contact-form *::-moz-placeholder {
  color: #4d4d4d;
  font-family: 'Lato', sans-serif;
  font-size: 14px; }

/* Firefox 19+ */
#ajax-contact-form *:-moz-placeholder {
  color: #4d4d4d;
  font-family: 'Lato', sans-serif;
  font-size: 14px; }

/* Firefox 18- */
#ajax-contact-form *:-ms-input-placeholder {
  color: #4d4d4d;
  font-family: 'Lato', sans-serif;
  font-size: 14px; }

#ajax-contact-form *:focus::-webkit-input-placeholder {
  color: #4d4d4d;
  font-family: 'Lato', sans-serif;
  font-size: 14px; }

#ajax-contact-form *:focus::-moz-placeholder {
  color: #4d4d4d;
  font-family: 'Lato', sans-serif;
  font-size: 14px; }

/* Firefox 19+ */
#ajax-contact-form *:focus:-moz-placeholder {
  color: #4d4d4d;
  font-family: 'Lato', sans-serif;
  font-size: 14px; }

/* Firefox 18- */
#ajax-contact-form *:focus:-ms-input-placeholder {
  color: #4d4d4d;
  font-family: 'Lato', sans-serif;
  font-size: 14px; }

#button_submit,
#fields .submit {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  margin: 0;
  color: #fff;
  text-decoration: none;
  background-image: linear-gradient(to right, #ad171f, #dd0f1a);
  position: relative;
  z-index: 10;
  display: inline-block;
  padding: 6px 10px;
  border-radius: 20px;
  text-align: center;
  text-transform: uppercase;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.16);
  border: none; }
  @media (max-width: 1329px) {
    #button_submit,
    #fields .submit {
      font-size: 14px; } }
  @media (max-width: 992px) {
    #button_submit,
    #fields .submit {
      max-width: 240px;
      margin-left: auto;
      margin-right: auto;
      float: none; } }
  @media (max-width: 767px) {
    #button_submit,
    #fields .submit {
      line-height: 20px; } }
  @media (max-width: 479px) {
    #button_submit,
    #fields .submit {
      font-size: 11px; } }
  #button_submit:hover,
  #fields .submit:hover {
    box-shadow: none; }
  #button_submit:active,
  #fields .submit:active {
    top: 3px;
    color: #fff;
    background-image: linear-gradient(to right, #ad171f, #dd0f1a); }
  #button_submit:focus,
  #fields .submit:focus {
    opacity: .5;
    top: 3px;
    box-shadow: none;
    color: #fff;
    background-image: linear-gradient(to right, #ad171f, #dd0f1a); }

.notification_ok {
  padding-bottom: 15px;
  color: #ad171f;
  line-height: 1.5em;
  font-size: 24px;
  text-align: center;
  padding-top: 15px; }
  .notification_ok a {
    text-decoration: none; }

.notification_error {
  color: #e54423;
  clear: both;
  text-align: left !important;
  font-size: 12px;
  line-height: 1.5em;
  font-weight: 700; }

#note {
  clear: both;
  font-family: 'Lato', sans-serif; }

#rc-imgelect, .g-recaptcha {
  width: 250px;
  transform: scale(0.82);
  transform-origin: 0 0;
  float: left;
  margin-right: 15px;
  /*@include break(custom){
	  transform:scale(0.74);
	  transform-origin:0 0;
	}*/ }
  @media (max-width: 1329px) {
    #rc-imgelect, .g-recaptcha {
      transform: scale(0.6);
      transform-origin: 0 0; } }
  @media (max-width: 992px) {
    #rc-imgelect, .g-recaptcha {
      transform: scale(0.56);
      transform-origin: 0 0; } }
  @media (max-width: 767px) {
    #rc-imgelect, .g-recaptcha {
      transform: scale(0.8);
      transform-origin: 0 0; } }
  @media (max-width: 479px) {
    #rc-imgelect, .g-recaptcha {
      float: none; } }

img {
  height: auto;
  max-width: 100%; }

.clearfix {
  clear: both; }

#ajax-contact-form label.error {
  color: #f00;
  font-size: 12px;
  margin-top: -8px;
  display: inline-block;
  font-weight: 400;
  clear: both; }

@media (max-width: 992px) {
  .mobile-none {
    display: none; } }

div#button_submit {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  letter-spacing: 0; }

/**     
mobile menu   mobile menu    mobile menu   mobile menu      mobile menu        **/
.slicknav_menu {
  font-size: 16px;
  box-sizing: border-box;
  background: #fff;
  display: none;
  z-index: 200;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  height: 87px;
  border-top: 7px #ad171f solid;
  border-bottom: 1px solid #cccccc;
  transition: .3s ease; }
  @media (max-width: 767px) {
    .slicknav_menu {
      display: block; } }
  .slicknav_menu .phone-mobile {
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    font-weight: 300;
    float: left;
    padding-left: 10px;
    padding-top: 6px; }
    .slicknav_menu .phone-mobile a {
      font-size: 16px;
      line-height: 28px;
      color: #fff;
      font-weight: 300; }
    @media (max-width: 479px) {
      .slicknav_menu .phone-mobile span {
        display: none; } }
  .slicknav_menu * {
    box-sizing: border-box; }
  .slicknav_menu .slicknav_menutxt {
    color: #fff;
    font-weight: bold;
    text-shadow: 0 1px 3px #000; }
  .slicknav_menu .slicknav_icon-bar {
    background-color: #f5841f; }

.slicknav_nav {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 0.875em;
  list-style: none;
  overflow: hidden;
  background: #f4f4f4;
  z-index: 9999;
  top: 24px;
  position: relative; }
  .slicknav_nav .slicknav_row:click, .slicknav_nav .slicknav_row:active, .slicknav_nav .slicknav_row:focus {
    color: #ffe49a;
    outline: none; }
  .slicknav_nav li:hover {
    background-color: #e0e0e0; }
  .slicknav_nav li a {
    text-align: left;
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 300;
    text-decoration: none;
    border-bottom: 1px #fff solid;
    padding-bottom: 0;
    text-transform: capitalize;
    padding-left: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    -webkit-transition: all 0.15s ease;
    -moz-transition: all 0.15s ease;
    -ms-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease;
    outline: none !important; }
    .slicknav_nav li a:hover {
      color: #ad171f; }
    @media (max-width: 767px) {
      .slicknav_nav li a + i {
        display: none; } }
  .slicknav_nav a.btn,
  .slicknav_nav a.btn:active,
  .slicknav_nav a.btn:focus {
    background: none;
    border: 0;
    border-top: 1px rgba(255, 255, 255, 0.2) dashed;
    border-bottom: 1px rgba(255, 255, 255, 0.2) dashed;
    text-transform: capitalize;
    border-radius: 0;
    padding-top: 0; }
  .slicknav_nav .current-menu-item a {
    color: #fff; }
  .slicknav_nav .slicknav_txtnode {
    margin-left: 15px; }
  .slicknav_nav .slicknav_item a {
    padding: 0;
    margin: 0; }
  .slicknav_nav .slicknav_parent-link a {
    padding: 0;
    margin: 0; }

.slicknav_brand {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  padding: 7px 12px;
  height: 44px; }

.slicknav_btn {
  display: block;
  margin: 0;
  padding: 0;
  text-decoration: none;
  border-radius: 0%;
  width: 60px;
  height: 52px;
  background: url("../img/mobile-button.png") 50% 0px no-repeat;
  background-size: 52px auto;
  float: left;
  margin: 0;
  margin-top: 4px;
  padding: 0;
  top: 10px;
  left: 10px;
  position: relative;
  outline: none !important;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease; }
  .slicknav_btn span {
    display: none; }
  .slicknav_btn.slicknav_open {
    background-position: 50% -50px; }

.slicknav_menu {
  *zoom: 1; }
  .slicknav_menu .slicknav_menutxt {
    display: block;
    line-height: 1.188em;
    float: left; }
  .slicknav_menu .slicknav_icon {
    float: left;
    width: 1.125em;
    height: 0.875em;
    margin: 0.188em 0 0 0.438em; }
    .slicknav_menu .slicknav_icon:before {
      background: transparent;
      width: 1.125em;
      height: 0.875em;
      display: block;
      content: "";
      position: absolute; }
  .slicknav_menu .slicknav_no-text {
    margin: 0; }
  .slicknav_menu .slicknav_icon-bar {
    display: block;
    width: 1.125em;
    height: 0.125em;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25); }
  .slicknav_menu:before {
    content: " ";
    display: table; }
  .slicknav_menu:after {
    content: " ";
    display: table;
    clear: both; }

.slicknav_nav {
  clear: both; }
  .slicknav_nav ul {
    list-style: none;
    overflow: hidden;
    padding: 10px 0 10px 0;
    margin: 0; }
    .slicknav_nav ul li a {
      text-transform: capitalize;
      line-height: 24px;
      color: #fff;
      border: none;
      font-size: 15px;
      font-weight: 400;
      padding-left: 30px;
      display: block; }
      .slicknav_nav ul li a:hover {
        color: #ffe49a; }
  .slicknav_nav .slicknav_arrow {
    font-size: 0.8em;
    color: #ffe49a;
    font-family: 'FontAwesome';
    float: right;
    margin-right: 20px; }
  .slicknav_nav .slicknav_item {
    cursor: pointer; }
    .slicknav_nav .slicknav_item a {
      display: inline;
      border-bottom: none; }
      .slicknav_nav .slicknav_item a:click, .slicknav_nav .slicknav_item a:active, .slicknav_nav .slicknav_item a:focus {
        color: #ffe49a;
        outline: none; }
  .slicknav_nav .slicknav_row {
    display: block; }
    .slicknav_nav .slicknav_row:click, .slicknav_nav .slicknav_row:active, .slicknav_nav .slicknav_row:focus {
      color: #fff;
      outline: none; }
  .slicknav_nav a {
    display: block; }
  .slicknav_nav .slicknav_parent-link a {
    display: inline; }

/**     O T H E R       **/
a {
  color: #00215c;
  text-decoration: underline; }

a:focus {
  color: #00215c;
  text-decoration: none; }

a:hover {
  text-decoration: none;
  color: #000; }

.center {
  text-align: center; }

.scrolloff {
  pointer-events: none; }

.map {
  position: relative;
  padding-bottom: 65%;
  padding-top: 30px;
  height: 0;
  background: #ccc; }
  .map iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0; }
  @media (max-width: 992px) {
    .map {
      margin-bottom: 20px; } }

ul.list {
  padding: 0 0 10px 10px;
  margin: 0;
  overflow: hidden; }
  ul.list li {
    padding-left: 24px;
    position: relative;
    padding-bottom: 14px;
    font-size: 18px;
    line-height: 1.34;
    color: #000;
    font-weight: 300;
    font-family: "Roboto Slab", serif; }
    @media (max-width: 767px) {
      ul.list li {
        font-size: 16px; } }
    ul.list li::after {
      width: 6px;
      height: 6px;
      position: absolute;
      left: 0px;
      top: 9px;
      border-radius: 50%;
      display: block;
      background: #a43719;
      content: '';
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25); }

.right {
  float: right; }
  @media (max-width: 767px) {
    .right {
      float: none; } }

.right_20 {
  margin-right: 25px; }

.paddcontent {
  padding-bottom: 20px; }
  @media (max-width: 767px) {
    .paddcontent {
      padding-bottom: 10px; } }

.smallpadd {
  padding-bottom: 10px; }

/* Buttons */
.btn:active, .btn:focus,
.btn {
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  font-size: 15px;
  line-height: 1;
  color: #141414;
  font-weight: 700;
  margin: 0;
  font-family: 'Encode Sans', sans-serif;
  text-decoration: none;
  position: relative;
  z-index: 10;
  display: inline-block;
  padding: 10px 18px;
  border-radius: 20px;
  text-align: center;
  text-transform: uppercase;
  text-shadow: none;
  background-image: linear-gradient(to right, #ad171f, #dd0f1a);
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.16);
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  line-height: normal; }
  .btn:active:hover, .btn:focus:hover,
  .btn:hover {
    box-shadow: none;
    color: #fff; }
  .btn:active::after, .btn:focus::after,
  .btn::after {
    content: "";
    font-family: 'simple-line-icons';
    display: inline-block;
    padding-left: 10px; }
  .btn:active:active, .btn:focus:active,
  .btn:active {
    top: 3px; }
  .btn:active:focus, .btn:focus:focus,
  .btn:focus {
    opacity: .5;
    top: 3px;
    box-shadow: none; }

hr {
  margin: 0 0 5px;
  padding: 0;
  background: #d7d7d7;
  height: 1px;
  width: 100%;
  border: 0; }

.red {
  color: #ba0003; }

a[href*="tel"] {
  text-decoration: none;
  cursor: default; }

::selection {
  background: #ededed !important;
  color: #000000; }

::-moz-selection {
  background: #ededed !important;
  color: #000000; }

/* OWL CAROUSEL */
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 60px;
  width: 40px; }

.owl-carousel .owl-nav .owl-prev {
  left: 0;
  margin-left: -30px; }
  @media (max-width: 767px) {
    .owl-carousel .owl-nav .owl-prev {
      margin-left: 10px; } }

.owl-carousel .owl-nav .owl-next {
  right: 0;
  margin-right: -30px; }
  .owl-carousel .owl-nav .owl-next img {
    float: right; }
  @media (max-width: 767px) {
    .owl-carousel .owl-nav .owl-next {
      margin-right: 10px; } }
