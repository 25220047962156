a, abbr, acronym, address, applet, article, aside, audio,
b, blockquote, big, body,
center, canvas, caption, cite, code, command,
datalist, dd, del, details, dfn, dl, div, dt,
em, embed,
fieldset, figcaption, figure, font, footer, form,
h1, h2, h3, h4, h5, h6, header, hgroup, html,
i, iframe, img, ins,
kbd,
keygen,
label, legend, li,
meter,
nav,
object, ol, output,
p, pre, progress,
q,
s, samp, section, small, span, source, strike, strong, sub, sup,
table, tbody, tfoot, thead, th, tr, tdvideo, tt,
u, ul,
var{background:transparent;border:0 none;font-size:100%;margin:0;padding:0;border:0;outline:0;vertical-align:top;}
ol, ul{list-style:none;}
blockquote, q{quotes:none;}
table, table td{padding:0;border:none;border-collapse:collapse;}
img{vertical-align:top;}
embed{vertical-align:top;}
*{border:none}
ul, li{list-style-image:none;margin:0;padding:0}
h1, h2, h3, h4, h5{font-weight:normal;}

body ul, body ol {
	margin-bottom: 0;
}